import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class OrderDataaService {
  public customers$ = [];
  public items$ = [];
  public drivers$ = [];       // customerDrivers
  public customerTypes$ = []; // customerTypes
  public orders$ = [];        // orderDetail

  public itemsMini$ = [];     // mikro = název, cena, počet

  public testdat = "původní data"

  constructor() {
    console.log("redirect?")
    this.zobrazit()
  }
  
  consolo() {
    console.log("redirect?")
    this.zobrazit()
  }

  zobrazit() {
    console.log(this.testdat)

  }

  zmenit(text) {
    console.log(this.testdat)
    this.testdat = "nová změněná data z " + text
  }

}
