<app-menu></app-menu>
<!-- TODO: -->
<!-- <div id="guide">
    <div class="flex flex-wrap">
        <div>
            <div *ngFor="let variable of variables ">
                <button class="button-guide" (click)="redirect(variable.url, variable?.api);">{{variable.name}}</button>
            </div>
        </div>
    </div>
</div> -->

<div id="guide">
    <div class="flexs-center">
            <div>
                <button class="button-guide" (click)="redirect(variables[0].url)">{{variables[0].name}}</button>
                <button class="button-guide" (click)="redirect(variables[1].url)">{{variables[1].name}}</button>
            </div>  
            <div>
                <button class="button-guide" (click)="redirect(variables[2].url)">{{variables[2].name}}</button>
                <button class="button-guide" (click)="redirect(variables[3].url)">{{variables[3].name}}</button>
            </div>
    </div>
</div>

