import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiCallService } from 'src/app/service/api-call.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  username: string;
  password: string;

  form: FormGroup;
  loading = false;
  submitted = false;

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private apiService: ApiCallService
  ) {
    sessionStorage.clear()
    localStorage.clear()}

  ngOnInit() {
    this.form = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required],
    });
  }

  get f() {
    return this.form.controls;
  }

  onSubmit() {
    this.submitted = true;

    // reset alerts on submit
    // this.alertService.clear();

    // stop here if form is invalid
    if (this.form.invalid) return

    this.loading = true;
    this.apiService.login(this.f.username.value, this.f.password.value).subscribe(
      (data) => {
        console.log(data);
        if (data.ERROR_CODE == 400) return
        localStorage.setItem('USERID', data.USERID);
        this.router.navigateByUrl('/guide');
      },
      error => console.error(error),
      () => this.loading = false
    );
  }

}
