import { ToastService } from './../../share/toast-info/toast.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiDataService } from 'src/app/service/api-data.service';
import { DataService } from 'src/app/service/data.service';
import { StorageService } from 'src/app/service/storage.service';

@Component({
  selector: 'app-overview',
  templateUrl: './overview.component.html',
  styleUrls: ['./overview.component.scss']
})
export class OverviewComponent implements OnInit {

  constructor( 
    private router: Router,
    public apiDataS: ApiDataService,
    private toastService: ToastService,
    private storageMemory: StorageService
  ) {
    this.apiDataS.callGetOrders();
    this.storageMemory.resetOrderDataLS();
  }

  ngOnInit() {}

  urlListToDetail(idOrder) {
    this.apiDataS.callOrderDetail(idOrder)
    this.showWait()
    setTimeout(() => {this.router.navigateByUrl('/order/detail/'+idOrder)}, 500);
  }

  showWait() {
    this.toastService.show('Hledám objednávku', { classname: 'bg-warning text-light', delay: 500 });
  }



}
