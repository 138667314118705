
<app-menu></app-menu>
<app-title-page textTitle="Úprava počtů jídel"></app-title-page>   

<div id="admin-balance" class="container p-0">
    <ng-container *ngFor="let item of apiDataS.items$; index as index">
        <div class="row my-2">
            <div class="col-1"></div>
            <div class="col-4">
                    <div class="row nomargin">
                      <div class="col-4">
                        <button
                          type="button"
                          class="button-own"
                          (click)='clickMinus(index)'>
                          -
                        </button>
                      </div>  
                      <div class="col-4">
                        <input
                          type="number"
                          [(ngModel)]='item.QUANTITY'>
                      </div>
                      <div class="col-4">
                        <button 
                          type="button" 
                          class="button-own" 
                          (click)='clickPlus(index)'
                          min="0">
                          +
                        </button>
                      </div>
                    </div>
                </div>
            <div class="col-7" style="font-size: 22px; padding: 12px 0px"> {{item.TITLE}}</div>
            <!-- <div class="col-1"></div> -->
        </div>
    </ng-container>

    <div class="row">
        <button 
            (click)="editPutItems()"
            class="col-3 mx-auto btn btn-lg btn-success my-3 p-3" 
            style="font-size: 24px; line-height: 1"> 
            Aktualizovat zůstatky
        </button>
    </div>
</div>