<app-menu></app-menu>

<div id="rozcestnik">
    <!-- <div class="center-grid"> -->
    <div>
        <div class="flex flex-wrap">
            <div *ngFor="let variable of variables ">
                <button class="button-rozcestnik" (click)="redirect(variable.url, variable?.api);">{{variable.name}}</button>
            </div>
        </div>
    </div>
</div>