import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { Injectable } from '@angular/core';
import { ApiCallService } from 'src/app/service/api-call.service';
import { ApiDataService } from 'src/app/service/api-data.service';
import { StorageService } from 'src/app/service/storage.service';

@Injectable({ providedIn: 'root' })

export class OrderDataService {

  // Items v objednávce
  oif = JSON.parse(localStorage.getItem('orderItemsFood')) || []; // OrderItemsFood
  oip = JSON.parse(localStorage.getItem('orderItemsPackage')) || []; // OrderItemsPackage
  
  // OrderCustomer
  oc = JSON.parse(localStorage.getItem('customer')) || {
    CUSTOMER_ID: '',
    NAME: '',
    STREET: '',
    CITY: '',
    PHONE: '',
    EMAIL: '',
    driver: {DRIVER_ID: '', NAME: ''},
    customerType: { CUSTOMER_TYPE_ID: '', DESCRIPTION:'' },
    CREDIT: ''
  }

  op = {
    priceAllItems: 0,
    priceSpecialAllItems: null,
    credit: null,
    boolTransport: true,
    // priceTransport: 0,
    description: localStorage.getItem('description') || "",
    ORDER_ID: ""
  }

  constructor(
    public apiCallS: ApiCallService,
    public apiDataS: ApiDataService,
    public storageMemory: StorageService,
  ) { 
  }

  /*createPackages() {
    console.log('OrderDataService -> createPackages -> this.apiDataS.itemsPackages$', this.apiDataS.itemsPackages$);
    this.oip = []
    console.log('OrderDataService -> createPackages -> this.apiDataS.itemsPackages$', this.apiDataS.itemsPackages$);
    this.apiDataS.itemsPackages$.map((item) => {
      console.log('OrderDataService -> createPackages -> item', item);
      let pack = {
        itemId: item.ITEM_ID,
        title: item.TITLE,
        priceSpecial: item.PRICE,
        balance: item.QUANTITY,
        quantity: 0,
        quantClick: 0,
        quantFood: 0,
        itemOrig: item
        }
        if(item.ITEM_ID == "3843" ) { pack.quantity = 1, pack.quantFood = 1 };
      this.oip.push(pack)
    })
    return
  }*/

  // console.warn("Info změně jídla")
  changeFood() { 
    this.changePackage();
  }
  
  // console.warn("Info změně balení")
  changePackage() {
    this.sumPackagesForItems();
    this.sumPriceOrder();
  }

  sumPackagesForItems() {
    this.oip.map(pack => pack.quantFood = 0);
    this.oip.map(pack => {
      this.oif.map(food => {
        if(pack.itemOrig.ITEM_ID == food.itemOrig.BOX_ID) {
          pack.quantFood += food.QUANTITY
        }
      })
    })

    return this.oip.map(pack => pack.QUANTITY = pack.quantFood + pack.quantClick);

    // TODO: dodělat automatický zobrazování ceny dopravy 
    // let sumAllFood = 0
    // sumAllFood > 5 ? this.op.priceTransport = 0 : this.op.priceTransport = 10
    // console.log('OrderDataService -> sumPackagesForItems -> sumAllFood', sumAllFood);
    
  }

  sumPriceOrder() {
    this.op.priceAllItems = 0;
    this.oif.map(food => {
      this.op.priceAllItems += food.QUANTITY*food.ITEMS_PRICE_SPECIAL
    })
    this.oip.map(pack => {
      this.op.priceAllItems += pack.QUANTITY*pack.ITEMS_PRICE_SPECIAL
    });
    
    // this.storageMemory.saveOrderAllLS(this.oc, this.oif, this.oip, this.op);
    return this.op.priceAllItems
  }

  sumCredit() {
    const finalPriceOrder = this.op.priceSpecialAllItems || this.op.priceAllItems
    return this.oc.CREDIT - finalPriceOrder
  }
}
