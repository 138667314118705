<app-menu></app-menu>
<div id="title-page">
    <h1 class="text-center">ADMIN: počet jídel</h1>
</div>   
<!-- {{dataS.items$ | json}} -->
<br>
<div id="admin-balance">
    <div class="container">
        <div class="row">
        </div>

        <ng-container *ngFor="let item of dataS.itemsMini$; index as index">
            <div class="row my-2">
                <div class="col-1"></div>
                <div class="col-4">
                        <div class="row nomargin">
                          <div class="col-4">
                            <button
                              type="button"
                              class="button-own"
                              (click)='clickMinus(index)'>
                              -
                            </button>
                          </div>  
                          <div class="col-4">
                            <input
                              type="number"
                              [(ngModel)]="item.balance">
                          </div>
                          <div class="col-4">
                            <button 
                              type="button" 
                              class="button-own" 
                              (click)='clickPlus(index)'
                              min="0">
                              +
                            </button>
                          </div>
                        </div>
                    </div>
                <div class="col-7" style="font-size: 22px; padding: 12px 0px"> {{item.title}}</div>
                <!-- <div class="col-1"></div> -->
            </div>
        </ng-container>
    </div>
</div>