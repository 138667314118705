import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-default-angular',
  templateUrl: './default-angular.component.html',
  styleUrls: ['./default-angular.component.scss']
})
export class DefaultAngularComponent implements OnInit {
  title = 'data-source';

  constructor() { }

  ngOnInit(): void {
  }

}
