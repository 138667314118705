import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DataService } from 'src/app/service/data.service';

interface IOrderWeb {
  ORDER_ID: string;
  CUSTOMER_ID: string;
  DRIVER_ID: string;
  CREATED_AT: string;
  CREATED_BY: string;
  DESCRIPTION: string;
  PRICE_SPECIAL: string;
  DELETED: string;
}

const ORDERWEB: IOrderWeb[] = [
  {
      "ORDER_ID": "1",
      "CUSTOMER_ID": "2",
      "DRIVER_ID": "1",
      "CREATED_AT": "2020-10-16 21:10:00",
      "CREATED_BY": "2",
      "DESCRIPTION": "pro VIP klienta (cenu ale nechat)",
      "PRICE_SPECIAL": null,
      "DELETED": "N"
  },
  {
      "ORDER_ID": "2",
      "CUSTOMER_ID": "4",
      "DRIVER_ID": "2",
      "CREATED_AT": "2020-10-18 22:52:20",
      "CREATED_BY": "3",
      "DESCRIPTION": "Testovací objednávka",
      "PRICE_SPECIAL": "999",
      "DELETED": "Y"
  }
]

@Component({
  selector: 'app-web-list-tmp',
  templateUrl: './web-list-tmp.component.html',
  styleUrls: ['./web-list-tmp.component.scss']
})
export class WebListTmpComponent implements OnInit {
  
  orderWeb;


  constructor( 
    public dataS: DataService,     
    private router: Router
    ) {
    this.dataS.callGetOrders();
  }

  ngOnInit(): void {
    this.orderWeb = this.dataS.orders$;
  }

  urlListToDetail(idOrder) {
    this.router.navigateByUrl('/order/detail/'+idOrder);
  }

}
