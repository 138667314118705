<!-- <app-menu></app-menu> -->

<!-- <app-title-page textTitle= "Zadání nové objednávky"></app-title-page>
<div id="title-page">
    <h1 class="text-center">Zadání nové objednávky</h1>
</div>

<app-order-customer></app-order-customer>
<hr class="my-4">
<app-order-items></app-order-items> -->

<!-- <p>order-new works!</p> -->
{{orderDS.testdat}}

<hr>
<div>
    <button (click)="orderDS.zmenit('info první')">BUTTON</button>
</div>
<hr>