import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})

export class DataService {
  // DELETE
  public customers$ = [];
  public orders$ = [];
  public items$ = [];
  public itemsMini$ = [];
  public drivers$ = [];

  public orderCustomer = JSON.parse(localStorage.getItem('orderCustomer')) || {};
  public orderItems = []; // ukládáme na FE vybrané jídla

  public orderItemPackage = [{
      "ITEM_ID": 3839,
      "ITEMS_TITLE": "Obal velký",
      "QUANTITY": 0,
      "ITEMS_PRICE": 5,
      "ITEMS_PRICE_SPECIAL": 5
  }]

  constructor(public apiService: ApiService) {
  }

  callGetOrders() {
    this.apiService.getOrders().subscribe(
      data => {
        this.orders$ = data;
        console.log(this.orders$)
      },
      error => console.error(error),
      () => this.orders$ 
    )
  }    
  
  callGetDrivers() {
    this.apiService.getDrivers().subscribe(
      data => {
        console.log(data)
        this.drivers$ = data
        console.log(this.drivers$)
      },
      error => console.error(error),
      () => this.drivers$ 
    )
  } 

  callGetItems(){
    this.itemsMini$ = [];
    this.items$ = [];
    this.apiService.getItems().subscribe(
      data => {
        this.items$ = data;
        data.map(i => this.itemsMini$.push({
          "itemId": Number(i.ITEM_ID),
          "title": i.TITLE,
          "price": Number(i.PRICE),
          "balance": Number(i.QUANTITY)}))
      },
      error => console.error(error),
      () => this.itemsMini$ 
    )
  }

  quantityOrderItems() {
    let orderItemPackagePcs
    // this.orderItems.map(is => orderItemPackagePcs += is.QUANTITY)
    this.orderItems.map(is => console.log(is))
    console.log(orderItemPackagePcs)
    return
  }
}
