import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiCallService } from 'src/app/service/api-call.service';
import { ApiDataService } from 'src/app/service/api-data.service';
import { StorageService } from 'src/app/service/storage.service';

@Component({
  selector: 'app-order-summary',
  templateUrl: './order-summary.component.html',
  styleUrls: ['./order-summary.component.scss']
})
export class OrderSummaryComponent implements OnInit {
  id: string;
  oi$;
  
  // ORDER_CUSTOMER = this.apiDataS.orderDetail$.ORDER_INFO.ORDER_CUSTOMER || []
  
  constructor(
    private apiCallS: ApiCallService,
    public apiDataS: ApiDataService,
    private route: ActivatedRoute,
    private router: Router,
    private storageMemory: StorageService,
    ) {
      this.id = this.route.snapshot.paramMap.get('id');
      this.apiDataS.callOrderDetail(this.id);
      this.storageMemory.createLocalStorageData()
      console.log("Máme???")
      console.log(this.storageMemory.localStorageData.detailOrder)
    }
    
    ngOnInit(): void {
      this.oi$ = this.storageMemory.localStorageData.detailOrder ||  this.apiDataS.orderDetail$;
    }
  
    urlSummaryToList() {
      this.router.navigateByUrl('/web-list')
    }
  
    urlDeleteToList() {
      fetch( `https://vojtas.plasanci.cz/API/ORDERS.php?ID=${this.id}`, 
        { method: 'DELETE', headers: this.apiCallS.myheader })
          .then(response => response.text())
          .then(result => this.urlSummaryToList())
          .catch(error => console.log('error', error));  
    }
  
    sumCredit() {
      return this.oi$.ORDER_INFO.ORDER_CUSTOMER.CREDIT - (this.oi$.ORDER_INFO.PRICE_SPECIAL || this.oi$.ORDER_INFO.PRICE)
    }
  
    urlSummaryToEdit() {
      this.router.navigateByUrl('/order/construct')
    }

 

}
  
  
