import { ApiDataService } from 'src/app/service/api-data.service';
import { Component, OnInit } from '@angular/core';
import { OrderDataService } from './order-data.service';

@Component({
  selector: 'app-order',
  templateUrl: './order.component.html',
  styleUrls: ['./order.component.scss']
})
export class OrderComponent implements OnInit {

  constructor(
    public apiDataS: ApiDataService,
    public ods: OrderDataService) {
    apiDataS.callGetItemsType();
    apiDataS.callGetItems();
    apiDataS.callGetCustomers();
    apiDataS.callGetDrivers();
    apiDataS.callGetCustomerType();
    this.ods.oip = JSON.parse(localStorage.getItem('orderItemsPackage')) || this.apiDataS.itemsPackages$;
    // this.ods.oif = this.apiDataS.itemsPackages$;
  }

  ngOnInit(): void {
  }

  clikenSie() {
  }
}
