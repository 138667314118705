import { OrderSummaryComponent } from './pages/order-summary/order-summary.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginTmpComponent } from './templates/login-tmp/login-tmp.component';
import { OrderNewTmpComponent } from './templates/order-new-tmp/order-new-tmp.component';
import { WebListTmpComponent } from './templates/web-list-tmp/web-list-tmp.component';
import { RozcestnikTmpComponent } from './templates/rozcestnik-tmp/rozcestnik-tmp.component';
import { OrderSummaryTmpComponent } from './templates/order-summary-tmp/order-summary-tmp.component';
import { OrderTmpComponent } from './templates/order-tmp/order-tmp.component';
import { AdminTmpComponent } from './templates/admin-tmp/admin-tmp.component';
import { OrderDetailTmpComponent } from './templates/order-detail-tmp/order-detail-tmp.component';
import { OrderNewTwoComponent } from './templates/order-new-two/order-new-two.component';
import { LoginComponent } from './pages/login/login.component';
import { OrderConstructComponent } from './pages/order-construct/order-construct.component';
import { OrderComponent } from './pages/order/order.component';
import { DefaultAngularComponent } from './pages/angular/default-angular.component';
import { GuideComponent } from './pages/guide/guide.component';
import { OverviewComponent } from './pages/overview/overview.component';
import { AdminComponent } from './pages/admin/admin.component';
import { OrderResumeComponent } from './pages/order-resume/order-resume.component';

const routes: Routes = [

    { path: 'angular', component: DefaultAngularComponent},
    
    { path: 'login', component: LoginComponent},
    { path: 'guide', component: GuideComponent},
    { path: 'overview', component: OverviewComponent},
    { path: 'admin', component: AdminComponent},
    
    { path: 'order', redirectTo: 'order/construct', pathMatch: 'full' },
    
    { path: 'order', component: OrderComponent,  children: [
      { path: 'construct', component: OrderConstructComponent },
      { path: 'summary', component: OrderSummaryComponent},
      { path: 'detail', component: OrderResumeComponent},
      { path: 'detail/:id', component: OrderResumeComponent}
    ]},  
    
    { path: '', redirectTo: 'login', pathMatch: 'full' },
    { path: '', redirectTo: 'login', pathMatch: 'full' },
    
    { path: 'old/login', component: LoginTmpComponent},
    { path: 'old/rozcestnik', component: RozcestnikTmpComponent},
    { path: 'old/order-new', component: OrderNewTmpComponent},
    { path: 'old/web-list', component: WebListTmpComponent},
    { path: 'old/admin', component: AdminTmpComponent},
    { path: 'old/report', component: LoginTmpComponent},
    { path: 'old/summary', component: OrderSummaryTmpComponent},
    { path: 'old/order/detail/:id', component: OrderDetailTmpComponent},
  ];
  
@NgModule({
  imports: [ RouterModule.forRoot(routes, { useHash: true }) ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
