import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ShareFunctionService } from 'src/app/service/share-function.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {

  constructor(public shareFuncitonS: ShareFunctionService, public router: Router ) { }

  ngOnInit() {
  }

  urlCreateToGuide() {
    console.log("urlCreateToGuide")
    this.router.navigateByUrl('/guide')
  }
}
