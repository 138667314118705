<!-- form goes here -->
<div id="login">
    <div class="container-for-center">
        <div class="center">
            <form [formGroup]="form" (ngSubmit)="onSubmit()">
                <div class="form-group">
                    <input type="text" formControlName="username" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.username.errors }" placeholder="Uživatelské jméno"  />
                    <div *ngIf="submitted && f.username.errors" class="invalid-feedback">
                        <div *ngIf="f.username.errors.required" style="font-size: 22px; margin-top: 10px;
                        ">Uživatelské jméno je povinné</div>
                    </div>
                </div>
                <div class="form-group">
                    <input type="password" formControlName="password" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.password.errors }" placeholder="Heslo" style="margin-top: 40px;"/>
                    <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                        <div *ngIf="f.password.errors.required" style="font-size: 22px; margin-top: 10px;
                        ">Heslo je povinné</div>
                    </div>
                </div>
    
            <button [disabled]="loading" class="btn btn-lg btn-success btn-block" type="submit" style="margin-top: 40px;">
                <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                Přihlásit se
            </button>
            <div *ngIf="submitted && !loading" style="font-size: 24px; color: crimson; margin-top: 10px;">
                Špatné  uživatelské jméno nebo heslo
            </div>
            </form>
        </div>
        </div>

</div>