import { ApiCallService } from './../../service/api-call.service';
import { ApiDataService } from 'src/app/service/api-data.service';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { OrderDataService } from 'src/app/pages/order/order-data.service';
import { StorageService } from 'src/app/service/storage.service';
import { ToastService } from 'src/app/share/toast-info/toast.service';

@Component({
  selector: 'app-order-customer',
  templateUrl: './order-customer.component.html',
  styleUrls: ['./order-customer.component.scss'],
})

export class OrderCustomerComponent implements OnInit {


  control = new FormControl();
  filteredCustomer: Observable<object[]>;
  form: FormGroup;
  loading = false;
  submitted = false;
  optCustomerType: string;
  optDriver: string; 
 


  constructor(
    private formBuilder: FormBuilder,
    public ods: OrderDataService,
    public apiCallS: ApiCallService,
    public apiDataS: ApiDataService,
    public storageMemory: StorageService,
    public toastService: ToastService
  ) { }

  ngOnInit() {
    this.createForm();
    this.startFilter();
  }

  /**
   * Create FORM, VALIDATION
   */
  createForm() {
		this.form = this.formBuilder.group({
			name: ['', Validators.required],
      street: ['', Validators.required],
      city: ['', Validators.required],
      phone: ['', Validators.required],
      email: ['', Validators.required],
      driver: ['', Validators.required],
      customerId: ['', Validators.required]
    });
  }

  get f() { return this.form.controls }

  onSubmit() {
    this.submitted = true;
    if(this.form.invalid) return
    this.loading = true;
  }

  /**
   * Předvýběr zákazníků v inputu
   */
  startFilter() {
    this.filteredCustomer = this.control.valueChanges.pipe(
      startWith(''),
      map((value) => this.filter(value))
		);
  }

  filter(value: string | ""): object[] {
    console.log(this.apiDataS.customers$);
    const filterValue = this._normalizeValue(value);
    return this.apiDataS.customers$.filter((cust) => this._normalizeValue(cust.NAME).includes(filterValue));
  }
    
  _normalizeValue(value: string): string { return value.toLowerCase().replace(/\s/g, ''); }

  chooseFilterCustomer(paramCustomer) {
    console.log(paramCustomer);
		this.ods.oc = paramCustomer,
    this.apiDataS.drivers$.map(x => x.driver == paramCustomer.CUSTOMER_TYPE_ID ? this.ods.oc.driver = x : null)
    this.apiDataS.customerType$.map(x => x.CUSTOMER_TYPE_ID == paramCustomer.CUSTOMER_TYPE_ID ? this.ods.oc.customerType = x : null)
    this.apiDataS.customerType$.map(x => x.CUSTOMER_TYPE_ID == paramCustomer.CUSTOMER_TYPE_ID ? this.optCustomerType = x : "" )
    this.storageMemory.saveCustomerLS(this.ods.oc)
  }

  //
  toggleSelectDriver(select: HTMLSelectElement, open: boolean) {
    select.size = open ? 10 : 0;
  }

  selectOptionDriver(event: Event, select: HTMLSelectElement) {
    event.stopPropagation();
    this.toggleSelectDriver(select, false);
  }  

  // Druhý
  toggleSelectType(selects: HTMLSelectElement, open: boolean) {
    console.log('OrderCustomerComponent -> toggleSelectType -> open', open);
    console.log('OrderCustomerComponent -> toggleSelectType -> select', selects);
    selects.size = open ? 10 : 0;
  }

  selectOptionType(event: Event, selects: HTMLSelectElement) {
    console.log('OrderCustomerComponent -> selectOptionType -> select', selects);
    console.log('OrderCustomerComponent -> selectOptionType -> event', event);
    event.stopPropagation();
    this.toggleSelectType(selects, false);
  }

  editPutCustomer() { console.info("API Editace uživatele")
    this.storageMemory.saveCustomerLS(this.ods.oc);
    const myHeaders = new Headers();
    myHeaders.append("LOGIN", localStorage.getItem('LOGIN'));
    myHeaders.append("PASSWORD", localStorage.getItem('PASSWORD'));
    
    const urlencoded = new URLSearchParams();
    urlencoded.append("ID", String(this.ods.oc.CUSTOMER_ID));
    urlencoded.append("NAME", String(this.ods.oc.NAME));
    urlencoded.append("DELETED", "N");
    urlencoded.append("LASTEDITED_BY", localStorage.getItem("USERID"));
    urlencoded.append("STREET", String(this.ods.oc.STREET));
    urlencoded.append("CITY", String(this.ods.oc.CITY));
    urlencoded.append("PHONE", String(this.ods.oc.PHONE));
    urlencoded.append("EMAIL", String(this.ods.oc.EMAIL));
    urlencoded.append("CUSTOMER_TYPE_ID", String(this.ods.oc.customerType.CUSTOMER_TYPE_ID));
    const requestOptions: RequestInit = {
      method: 'PUT',
      headers: myHeaders,
      body: urlencoded,
      redirect: 'follow'
    };
    fetch("https://vojtas.plasanci.cz/API/CUSTOMERS.php", requestOptions)
      .then(response => response.text())
      .then(result => {this.apiDataS.callGetCustomers(), this.showSuccess('Zákazník upraven v databázy')})
      .catch(error => console.log('error', error));
  }

  addPostCustomer() { console.info("API Nový uživatel")
    this.storageMemory.saveCustomerLS(this.ods.oc);
    const formdata = new FormData();
    formdata.append("NAME", String(this.ods.oc.NAME) || "0");
    formdata.append("CUSTOMER_TYPE_ID", String(this.ods.oc.customerType.CUSTOMER_TYPE_ID) || "0");
    formdata.append("CREATED_BY", localStorage.getItem("USERID"));
    formdata.append("PASSWORD", "nonono");
    formdata.append("STREET", String(this.ods.oc.STREET) || "0");
    formdata.append("CITY", String(this.ods.oc.CITY) || "0");
    formdata.append("PHONE", String(this.ods.oc.PHONE) || "0");
    formdata.append("EMAIL", String(this.ods.oc.EMAIL) || "0");
    formdata.append("CREDIT", String(this.ods.oc.CREDIT) || "0");
    this.apiCallS.postCustomer(formdata).subscribe(
      data => {this.ods.oc.CUSTOMER_ID = data.ID; this.showSuccess('Zákazník přidán do databáze')},
      error => console.log(error),
      () => this.apiDataS.callGetCustomers()
    )
  }

  showSuccess(text) {
    this.toastService.show(text, { classname: 'bg-success text-light', delay: 1500 });
  }

  resetCustomer() {
    this.ods.oc = { CUSTOMER_ID: '', NAME: '', STREET: '', CITY: '', PHONE: '', EMAIL: '', driver: {DRIVER_ID: '', NAME: ''}, customerType: { CUSTOMER_TYPE_ID: '', DESCRIPTION:'' }, CREDIT: '' };
    localStorage.removeItem('customer')
  }
}
