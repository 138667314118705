import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiDataService } from 'src/app/service/api-data.service';
import { StorageService } from 'src/app/service/storage.service';

@Component({
  selector: 'app-guide',
  templateUrl: './guide.component.html',
  styleUrls: ['./guide.component.scss']
})
export class GuideComponent implements OnInit {


  constructor(
    public apiDataS: ApiDataService,
    private storageMemory: StorageService,
    private router: Router,
  ) { apiDataS.callGetItems() }

  ngOnInit(): void {
  }

  variables = [
    {
      name: "Nová objednávka",
      url: "order",
      api: "callGetOrders"
    },
    {
      name: "Přehled objednávek",
      url: "overview",
      api: "callGetOrders"
    },
    {
      name: "Administrace",
      url: "admin",
      api: "callGetOrders"
    },
    {
      name: "Reporty",
      url: "report",
      api: "callGetOrders"
    }
  ];

  redirect(url, api?) {
    this.storageMemory.resetOrderDataLS();
    this.router.navigateByUrl('/' + url);
  }

}
