import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { MenuComponent } from './section/menu/menu.component';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginTmpComponent } from './templates/login-tmp/login-tmp.component';
import { HttpClientModule } from '@angular/common/http';
import { RozcestnikTmpComponent } from './templates/rozcestnik-tmp/rozcestnik-tmp.component';
import { OrderNewTmpComponent } from './templates/order-new-tmp/order-new-tmp.component';
import { AdminTmpComponent } from './templates/admin-tmp/admin-tmp.component';
import { RouterModule } from '@angular/router';
import { WebListTmpComponent } from './templates/web-list-tmp/web-list-tmp.component';
import { OrderCustomerComponent } from './section/order-customer/order-customer.component';

import { OrderItemsComponent } from './section/order-items/order-items.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModuleModule } from './share/material-module/material-module.module';
import { MatNativeDateModule } from '@angular/material/core';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { CurrencyCzkPipe } from './pipes/currency-czk.pipe';
import { TitlePageComponent } from './section/title-page/title-page.component';
import { OrderSummaryTmpComponent } from './templates/order-summary-tmp/order-summary-tmp.component';
import cs from '@angular/common/locales/cs';
import { OrderTmpComponent } from './templates/order-tmp/order-tmp.component';
import { OrderDetailTmpComponent } from './templates/order-detail-tmp/order-detail-tmp.component';
import { OrderNewTwoComponent } from './templates/order-new-two/order-new-two.component';
import { LoginComponent } from './pages/login/login.component';
import { GuideComponent } from './pages/guide/guide.component';
import { AdminComponent } from './pages/admin/admin.component';
import { OverviewComponent } from './pages/overview/overview.component';
import { OrderComponent } from './pages/order/order.component';
import { ReportComponent } from './pages/report/report.component';
import { DefaultAngularComponent } from './pages/angular/default-angular.component';
import { OrderConstructComponent } from './pages/order-construct/order-construct.component';
import { OrderButtonComponent } from './section/order-button/order-button.component';
import { OrderPriceComponent } from './section/order-price/order-price.component';
import { OrderItemsPackagesComponent } from './section/order-items-packages/order-items-packages.component';
import { OrderSummaryComponent } from './pages/order-summary/order-summary.component';
import { OrderResumeComponent } from './pages/order-resume/order-resume.component';
import { OrderDetailComponent } from './section/order-detail/order-detail.component';
import { ToastsContainer } from './share/toast-info/toasts-container.component';
import { CodebookDriverDirective } from './directives/codebook-driver.directive';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    NgbModule,
    MaterialModuleModule,
    MatNativeDateModule,
    ReactiveFormsModule
  ],
  declarations: [
    AppComponent,
    LoginTmpComponent,
    DefaultAngularComponent,
    MenuComponent,
    RozcestnikTmpComponent,
    OrderNewTmpComponent,
    AdminTmpComponent,
    WebListTmpComponent,
    OrderCustomerComponent,
    OrderItemsComponent,
    CurrencyCzkPipe,
    TitlePageComponent,
    OrderSummaryTmpComponent,
    OrderTmpComponent,
    OrderDetailTmpComponent,
    OrderNewTwoComponent,
    LoginComponent,
    GuideComponent,
    AdminComponent,
    OverviewComponent,
    OrderComponent,
    ReportComponent,
    OrderConstructComponent,
    OrderButtonComponent,
    OrderPriceComponent,
    OrderItemsPackagesComponent,
    OrderSummaryComponent,
    OrderResumeComponent,
    OrderDetailComponent,
    ToastsContainer,
    CodebookDriverDirective
  ],
  exports: [
    WebListTmpComponent,
    OrderCustomerComponent,
    OrderItemsComponent,
    TitlePageComponent,
    OrderSummaryTmpComponent,
    OrderConstructComponent,
    OrderDetailComponent
  ],
  providers: [
    {provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'fill' }},
    {provide: LOCALE_ID, useValue: cs},
    {provide: LocationStrategy, useClass: HashLocationStrategy}],
  bootstrap: [AppComponent],
  entryComponents: [OrderCustomerComponent]
})
export class AppModule { }
