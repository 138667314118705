import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/service/data.service';

@Component({
  selector: 'app-admin-tmp',
  templateUrl: './admin-tmp.component.html',
  styleUrls: ['./admin-tmp.component.scss']
})
export class AdminTmpComponent implements OnInit {


  constructor( public dataS: DataService) { 
    this.dataS.callGetItems(); 
  }

  ngOnInit() {
  }

  clickMinus(ind) {
    console.log(ind)
    console.log(this.dataS.itemsMini$[ind])
    return this.dataS.itemsMini$[ind].balance += 1
  }

  clickPlus(ind){
    return this.dataS.itemsMini$[ind].balance += 1
  }
}
