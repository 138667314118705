import { ApiDataService } from './../../service/api-data.service';
import { Component, OnInit } from '@angular/core';
import { ApiCallService } from 'src/app/service/api-call.service';
import { ToastService } from 'src/app/share/toast-info/toast.service';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})
export class AdminComponent implements OnInit {

  constructor(     
    private apiCallS: ApiCallService,
    public apiDataS: ApiDataService,
    private toastService: ToastService
  ) {this.apiDataS.callGetItems() }

  ngOnInit(): void {
  }
  
  clickMinus(ind) {
    Number(this.apiDataS.items$[ind].QUANTITY) > 0 ? 
      this.apiDataS.items$[ind].QUANTITY -= 1 : 
      this.apiDataS.items$[ind].QUANTITY
    // this.apiDataS.items$[ind].QUANTITY = Number(this.apiDataS.items$[ind].QUANTITY) | 0
    return this.apiDataS.items$[ind].QUANTITY -= 1
  }

  clickPlus(ind){
    this.apiDataS.items$[ind].QUANTITY = Number(this.apiDataS.items$[ind].QUANTITY) | 0
    // this.apiDataS.items$[ind].QUANTITY = this.apiDataS.items$[ind].QUANTITY ? this.apiDataS.items$[ind].QUANTITY + 1 : 1
    return this.apiDataS.items$[ind].QUANTITY += 1
  }

     
  editPutItems() { console.info("API Editace jídel")
  // this.storageMemory.saveCustomerLS(this.ods.oc);

  const myHeaders = new Headers();
    myHeaders.append("LOGIN", localStorage.getItem('LOGIN'));
    myHeaders.append("PASSWORD", localStorage.getItem('PASSWORD'));


  const ITEMS_DETAIL = this.apiDataS.items$;
  console.log('AdminComponent -> editPutItems -> ITEMS_DETAIL', ITEMS_DETAIL);

  const urlencoded = new URLSearchParams();
    urlencoded.append("ITEMS_DETAIL", "");
  // urlencoded.append("ITEMS_DETAIL", String(ITEMS_DETAIL));
  console.log('AdminComponent -> editPutItems -> urlencoded', urlencoded);
  console.log('AdminComponent -> editPutItems -> this.apiDataS.items$', this.apiDataS.items$);
  
  const requestOptions: RequestInit = {
    method: 'PUT',
    headers: myHeaders,
    body: urlencoded,
    redirect: 'follow'
  };

  fetch("https://vojtas.plasanci.cz/API/ITEMS.php", requestOptions)
    .then(response => response.text())
    .then(result => this.apiDataS.callGetItems() )
    .catch(error => console.log('error', error));
  }

  showSuccess() {
    this.toastService.show("Změny uloženy", { classname: 'bg-success text-light', delay: 1500 });
  }


}
