import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { IOrder } from '../models/IOrder';
import { IOrderDetail } from '../models/IOrderDetail';
import { ApiCallService } from './api-call.service';
import { ToastService } from '../share/toast-info/toast.service';

@Injectable({
  providedIn: 'root'
})
export class ApiDataService {
  public customers$ = [];
  public orders$ = [];
  public orderDetail$ = [];
  public items$ = [];
  public itemsFood$ = [];
  public itemsPack$ = [];
  public itemsPackages$ = [];
  public itemsMini$ = [];
  public drivers$ = [];
  public customerType$ = [];
  public itemsType$ = [];
  
  constructor(public apiCallS: ApiCallService, private router: Router, public toastService: ToastService ) { }

  callGetOrders() {
    this.apiCallS.getOrders().subscribe(
      response => this.orders$ = response,
      error => console.error(error),
      () => console.info(this.orders$) 
    )
  }    

  callGetDrivers() {
    this.apiCallS.getDrivers().subscribe(
      response => this.drivers$ = response,
      error => console.error(error),
      () => console.info(this.drivers$) 
    )
  } 

  callOrderDetail(number) {
    this.apiCallS.getOrderDetail(number).subscribe(
      response => this.orderDetail$ = response,
      error => console.error(error),
      () => console.info(this.orderDetail$) 
    )
  }

  callGetItems(){
    // this.itemsFood$ = []; 
    this.apiCallS.getItems().subscribe(
      response => {
        // this.itemsPackages$ = []
        this.items$ = response;
        this.separateItems(response)
      },
      error => console.error(error),
      () => console.info(this.items$, this.itemsFood$, this.itemsPack$) 
    )
  }

  callGetCustomers(){
    this.apiCallS.getCustomers().subscribe(
      response => this.customers$ = response,
      error => console.error(error),
      () => console.info(this.customers$) 
    )
  }  
  
  callGetCustomerType(){
    this.apiCallS.getCustomerTypes().subscribe(
      response => this.customerType$ = response,
      error => console.error(error),
      () => console.info(this.customerType$) 
    )
  }

  callGetItemsType(){
    this.apiCallS.getItemsType().subscribe(
      response => this.itemsType$ = response,
      error => console.error(error),
      () => console.info(this.itemsType$) 
    )
  }

  separateItems(response) {
    this.itemsPack$ = []
    this.itemsFood$ = []
    this.itemsPackages$ = [];

    response.map(item => 
      item.ITEM_TYPE_ID == 3 || item.ITEM_TYPE_ID == 75 ? 
        this.itemsPack$.push(item) :
        this.itemsFood$.push(item)
    ) 
    this.createPackages()  
    console.log('ApiDataService -> separateItems -> this.itemsFood', this.itemsFood$);
    console.log('ApiDataService -> separateItems -> this.itemsPackages', this.itemsPackages$);
  }

  createPackages() {
    this.itemsPack$.map((item) => {
      console.log('OrderDataService -> createPackages -> item', item);
      let pack = {
        ITEM_ID: item.ITEM_ID,
        ITEMS_TITLE: item.TITLE,
        ITEMS_PRICE_SPECIAL: Number(item.PRICE),
        balance: item.QUANTITY,
        QUANTITY: 0,
        quantClick: 0,
        quantFood: 0,
        itemOrig: item
      }
      if(item.ITEM_ID == "3843" ) { pack.QUANTITY = 1, pack.quantFood = 1 };
      this.itemsPackages$.push(pack)
    })
    console.log('ApiDataService -> createPackages -> this.itemsPackages$', this.itemsPackages$);
    localStorage.removeItem('orderItemsPackage');
    localStorage.setItem('orderItemsPackage', JSON.stringify(this.itemsPackages$))
    return
  }


  addPostOrderDetail(storageData) {
    const myHeaders = new Headers();
      myHeaders.append("LOGIN", "api@jirka.cz");
      myHeaders.append("PASSWORD", "Smazakpodebradsky");

      let formdata = new FormData();
      
      let orderItems = [];
      
      storageData.orderItemsAll.map(item => orderItems.push( `
        {"ITEM_ID": "${String(item.ITEM_ID)}", 
        "QUANTITY": "${String(item.QUANTITY)}", 
        "ITEMS_PRICE_SPECIAL": "${String(item.ITEMS_PRICE_SPECIAL)}"
        }
        `))      

      formdata.append('ORDER_DETAIL', `{ 
        "ORDER_INFO": {
        "CUSTOMER_ID": "${String(storageData.customer.CUSTOMER_ID)}",
        "DRIVER_ID": "${String(storageData.customer.driver.DRIVER_ID)}",
        "CREATED_BY": "${String(localStorage.getItem("USERID"))}",
        "DESCRIPTION": "${storageData.orderPrice.description}",
        "PRICE":${storageData.orderPrice.priceAllItems},
        "PRICE_SPECIAL":${storageData.orderPrice.priceSpecialAllItems},
        "ORDER_ITEMS": [ ${String(orderItems)} ] 
      }
    }`)
      
    let requestOptions: RequestInit = {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    };

    console.log("requestOptions")
    console.log(requestOptions)

    fetch("https://vojtas.plasanci.cz/API/ORDER_DETAIL.php", requestOptions)
      .then(response => response.text())
      .then(result => {console.log(result); this.showSuccess(); this.router.navigateByUrl('/guide')})
      .catch(error => {console.log('error', error) });
  }

  showSuccess() {
    this.toastService.show('Data úspěšně uloženy', { classname: 'bg-success text-light', delay: 1500 });
  }

}
