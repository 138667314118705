import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-title-page',
  template: `
  <div id="title-page">
    <h1 class="text-center">{{textTitle}}</h1>
  </div>
  `,
  styles: [`
  #title-page  {
    background-color:rgba(0, 123, 255, 0.5);
  }
  
  h1, .h1 {
    font-size: 36px;
    padding: 10px 25px;
    margin: 0;
  }
  `]
})
export class TitlePageComponent implements OnInit {
  @Input() textTitle;
  
  constructor() { }

  ngOnInit(): void {
  }

}
