<div id="order-customer">
  <div class="container my-2">
    <!-- VYHLEDÁVÁNÍ KLIENTA -->
    <ng-container *ngIf="!ods.oc.NAME">
      <div class="row">
        <div class="col-9 mx-auto search-input">
            <input
              id="search-customer"
              class="form-control"
              type="text"
              placeholder="VYHLEDAT KLIENTA"
              [formControl]="control"
              [matAutocomplete]="auto"/>
            <mat-autocomplete #auto="matAutocomplete">
              <mat-option
                *ngFor="let customer of filteredCustomer | async"
                [value]="customer.NAME">
                <div (click)="chooseFilterCustomer(customer)">
                  {{ customer.NAME }}
                </div>
              </mat-option>
            </mat-autocomplete>
        </div>
      </div>
    </ng-container>

    <!-- BUTTONY PRO PRÁCI S KLIENTEM -->
    <ng-container *ngIf="ods.oc.NAME">
      <div class="row justify-content-around my-2">
        
        <button class="btn btn-lg btn-secondary btn-block"
          (click)="resetCustomer()">
          Vyhledat jiného klienta
        </button>

        <button *ngIf="!ods.oc.CUSTOMER_ID"
          class="btn btn-lg btn-success btn-block" 
          type="submit"
          (click)="addPostCustomer()">
            Uložit nového klienta
        </button>     

        <button *ngIf="ods.oc.CUSTOMER_ID"
          class="btn btn-lg btn-success btn-block" 
          type="submit"
          (click)="editPutCustomer()">
            Upravit údaje klienta
        </button>
      </div>
    </ng-container>
    
    <!-- INFORMACE PRO CHOVÁNÍ S KLIENTEM -->
    <form [formGroup]="form" (ngSubmit)="onSubmit()">

      <!-- 1. ŘÁDEK -->
      <div class="row justify-content-around my-2">
        <div class="col-md-4">
          <input
            type="text"
            formControlName="name"
            class="form-control"
            [(ngModel)]="ods.oc.NAME"
            placeholder="Jméno, příjmení, firma" />
          <ng-container *ngTemplateOutlet="errorTmp; context: { $implicit: 'f.name.errors' }"></ng-container>
        </div>

        <div class="col-md-4">
          <input
            type="text"
            formControlName="street"
            class="form-control"
            [(ngModel)]="ods.oc.STREET"
            placeholder="Ulice, čp" />
          <ng-container *ngTemplateOutlet="errorTmp; context: { $implicit: 'f.street.errors' }"></ng-container>
        </div>

        <div class="col-md-4">
          <input
            type="text"
            formControlName="city"
            class="form-control"
            [(ngModel)]="ods.oc.CITY"
            placeholder="Město" />
          <ng-container *ngTemplateOutlet="errorTmp; context: { $implicit: 'f.city.errors' }"></ng-container>
        </div>
      </div>

      <!-- 2. ŘÁDEK -->
      <div class="row justify-content-around my-2">
        <div class="col-md-4">
          <input
            type="text"
            formControlName="phone"
            class="form-control"
            [(ngModel)]="ods.oc.PHONE"
            placeholder="Telefonní číslo +420" />
          <ng-container *ngTemplateOutlet="errorTmp; context: { $implicit: 'f.phone.errors' }"></ng-container>
        </div>

        <div class="col-md-8">
          <input
            type="text"
            formControlName="email"
            class="form-control"
            [(ngModel)]="ods.oc.EMAIL"
            placeholder="Email" />
          <ng-container *ngTemplateOutlet=" errorTmp; context: { $implicit: 'f.email.errors' }"></ng-container>
        </div>
      </div>
    </form>
    <div class="row">
      <div class="col-md-4">
        <select 
          class="form-control select-open-button"
          [(ngModel)]="ods.oc.driver"
          (change)="ods.oc.driver = ods.oc.driver; storageMemory.saveCustomerLS(ods.oc);">
            <option *ngFor="let optDriver of apiDataS.drivers$" class="option" [ngValue]="optDriver">
              {{optDriver.NAME}}
            </option>
        </select>
    
          <button class="reset-button button-select">
             {{ods.oc.driver?.NAME || "- - - Vyberte dopravu - - -"}}
          </button>
        </div>
        
        <div class="col-md-4">
            <select 
              class="form-control select-open-button" 
              #selects (click)="selectOptionType($event, selects)"
              [(ngModel)]="ods.oc.customerType"
              (change)="ods.oc.customerType = ods.oc.customerType; storageMemory.saveCustomerLS(ods.oc)">
              <option *ngFor="let optCustomerType of apiDataS.customerType$" class="option" [ngValue]="optCustomerType">
                {{optCustomerType.DESCRIPTION}}
              </option>
            </select>

            <button class="reset-button button-select">
              {{ods.oc.customerType?.DESCRIPTION || "- - - Vyberte platbu - - -"}}
            </button>
        </div>
        <div *ngIf="ods.oc.customerType.CUSTOMER_TYPE_ID == '1'" class="col-md-4 search-label" style="text-align: left;">
          Stav konta: {{ods.oc.CREDIT}} Kč
        </div>
      </div>
    <!-- </form> -->
  </div>
</div>

<ng-template #errorTmp let-one>
  <div
    *ngIf="submitted && one"
    class="invalid-feedback"
    style="font-size: 18px; margin-left: 10px">
    Pole je povinné
  </div>
</ng-template>
