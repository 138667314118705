import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiCallService } from 'src/app/service/api-call.service';
import { ApiDataService } from 'src/app/service/api-data.service';
import { StorageService } from 'src/app/service/storage.service';

@Component({
  selector: 'app-order-detail',
  templateUrl: './order-detail.component.html',
  styleUrls: ['./order-detail.component.scss']
})
export class OrderDetailComponent implements OnInit {
id: string;
oi$;

// ORDER_CUSTOMER = this.apiDataS.orderDetail$.ORDER_INFO.ORDER_CUSTOMER || []

constructor(
  private apiCallS: ApiCallService,
  public apiDataS: ApiDataService,
  private route: ActivatedRoute,
  private router: Router,
  public storageMemory: StorageService
  ) {
    console.info("Construct detail")
    this.id = this.route.snapshot.paramMap.get('id');
    this.apiDataS.callOrderDetail(this.id);    
  }
  
  ngOnInit(): void {
    let dataLoad;
    if(!this.apiDataS.orderDetail$) {
      dataLoad = JSON.parse(localStorage.getItem("detailOrder"))
      console.log('OrderDetailComponent -> ngOnInit -> JSON.parse(localStorage.getItem("detailOrder"))', JSON.parse(localStorage.getItem("detailOrder")));
    } else {
      this.storageMemory.createDataFromDB(this.oi$)
      dataLoad = this.apiDataS.orderDetail$
      console.log('OrderDetailComponent -> ngOnInit -> this.apiDataS.orderDetail$', this.apiDataS.orderDetail$);
    }
    this.oi$ = dataLoad;

    setTimeout(()=>{
      this.storageMemory.createDataFromDB(this.oi$)
    },1500);;
  }

  urlSummaryToList() {
    this.router.navigateByUrl('/overview')
  }

  urlDeleteToList() {
    fetch( `https://vojtas.plasanci.cz/API/ORDERS.php?ID=${this.id}`, 
      { method: 'DELETE', headers: this.apiCallS.myheader })
        .then(response => response.text())
        .then(result => this.urlSummaryToList())
        .catch(error => console.log('error', error));  
  }

  // this.storageMemory.saveOrderAllLS(this.ods.oc, this.ods.oif, this.ods.oip, this.ods.op);

}

