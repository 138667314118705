<app-menu></app-menu>

<div id="title-page">
    <h1 class="text-center">DETAIL OBJEDNÁVKY</h1>
  </div>

  <div id="order-summary-customer">
    <div class="container my-2">
      <div class="row my-2">
        <div class="col-md-4"> 
          <p class="label"> Jméno,  Příjmení, Firma: </p>
          <p class="info"> {{ oi.ORDER_CUSTOMER.NAME }} </p> 
        </div>
        <div class="col-md-4"> 
          <p class="label"> Adresa: </p>
          <p class="info"> {{ oi.ORDER_CUSTOMER.STREET }} </p> 
        </div>
        <div class="col-md-4"> 
          <p class="label"> Město: </p> 
          <p class="info"> {{ oi.ORDER_CUSTOMER.CITY }} </p> 
        </div>
      </div>
  
      <div class="row my-2">
        <div class="col-md-4"> 
          <p class="label"> Telefon: </p>
          <p class="info"> {{ oi.ORDER_CUSTOMER.PHONE }} </p> 
        </div>
        <div class="col-md-8"> 
          <p class="label"> Email: </p>
          <p class="info"> {{ oi.ORDER_CUSTOMER.EMAIL }} </p> 
        </div>
      </div>
      <div class="row my-2">
        <div class="col-md-4"> 
          <p class="label"> Doručení: </p>
          <p class="info"> {{ oi.ORDER_CUSTOMER.CUSTOMER_TYPE_ID }} </p> 
        </div>
        <div class="col-md-4"> 
          <p class="label"> Typ platby: </p>
          <p class="info"> {{ oi.ORDER_CUSTOMER.CUSTOMER_TYPE_DESCRIPTION }} </p> 
        </div>
        <!-- <div class="col-md-4"> <p class="sum-info" *ngIf="actCustomer.customerType.typeId == 1 "> {{ actCustomer.customerType.description }} </p> </div> -->
      </div>
    </div>
  </div>
  
  <hr>
  
  <div id="order-summary-items">
    <div class="container my-2">
      <ng-container *ngFor="let orderItem of oi.ORDER_ITEMS; index as i" class="order-items">
        <div class="row item my-3">
          <div class="col-7"> {{i+1}}) {{ orderItem.ITEMS_TITLE }} </div>
          <div class="col-2 text-right"> {{ orderItem.ITEMS_PRICE_SPECIAL }}Kč/ks</div>
          <div class="col-1 text-center"> {{ orderItem.QUANTITY }}ks </div>
          <div class="col-2 text-right">
            <span>∑ </span>
            <span>{{ orderItem.QUANTITY * orderItem.ITEMS_PRICE_SPECIAL }}</span>
            <span> ,00 Kč </span>
          </div>
          <div class="col-1"></div>
        </div>
      </ng-container>
    </div>
  </div>
  
  
  <div id="order-summary-price">
    <div class="container">
      <div class="row p-1">
  
        <div class="column" style="font-size: 18px;">
          <ng-container *ngIf="oi.DESCRIPTION">
            <div>
              Poznámka:
            </div>
            <div style="font-weight: 400;">
              {{oi.DESCRIPTION}}
            </div>
          </ng-container>
        </div>
  
        <div class="column" >
            <div>
              <div class="fl"  [ngStyle]="{'text-decoration' : oi.PRICE_SPECIAL ? 'line-through' : 'none'}">Objednávka celkem:</div>
              <div class="fr"  [ngStyle]="{'text-decoration' : oi.PRICE_SPECIAL ? 'line-through' : 'none'}">{{oi.PRICE}},00 Kč</div>
            </div>  
            
            <div *ngIf="oi.PRICE_SPECIAL">
              <div>
                <div class="fl">Speciální cena:</div>
                <div class="fr">{{oi.PRICE_SPECIAL}},00 Kč</div>
              </div>
            </div>
        </div>
      </div>
    </div>
  </div>
  
  <div class="container mt-5 mb-3 mininav">
    <div class="row justify-content-around">
      <button class="col-3 btn btn-lg btn-secondary p-3" style="font-size: 24px; line-height: 1" (click)="urlSummaryToList()" > ZPĚT </button>
      <button class="col-3 btn btn-lg btn-danger p-3" style="font-size: 24px; line-height: 1" (click)="urlDeleteToList()"> SMAZAT </button>
      <button class="col-3 btn btn-lg btn-succes p-3" style="font-size: 24px; line-height: 1; border: 1px solid slategrey" (click)="urlSummaryToList()"> UPRAVIT -neimplementováno </button>
      <!-- <button class="col-3 btn btn-lg btn-secondary p-3" style="font-size: 24px; line-height: 1" (click)="redirect("rozcetnik")"> Zrušit objednávku </button> -->
      <!-- <button class="col-3 btn btn-lg btn-success p-3" style="font-size: 24px; line-height: 1" (click)="sendconsol()"> DOKONČIT </button> -->
    </div>
    <!-- <div class="row">
      <div class="col-8"></div>
      <div class="col-4">
        <div *ngIf="errorSendDelete" class="chyba při odeslání">Chyba při m objednávky</div>
      </div>
  
    </div> -->
  </div>