import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class ShareFunctionService {

  constructor(private router: Router) { }

  logout() {
    console.error("Promazat local storage!!!");
    localStorage.removeItem('LOGIN');
    localStorage.removeItem('PASSWORD');
    localStorage.removeItem('USERID');
    this.router.navigateByUrl('/login');
  }

  cancelCreateOrder() {
    this.router.navigateByUrl('/guide');
  }

}
