import { Component, OnInit } from '@angular/core';
import { OrderDataService } from 'src/app/pages/order/order-data.service';
import { ApiDataService } from 'src/app/service/api-data.service';

@Component({
  selector: 'app-order-items-packages',
  templateUrl: './order-items-packages.component.html',
  styleUrls: ['./order-items-packages.component.scss']
})
export class OrderItemsPackagesComponent implements OnInit {

  constructor(    
    public apiDataS: ApiDataService,
    public ods: OrderDataService
  ) { }

  ngOnInit(): void {
  }

  getSum(a, b) {
    this.ods.sumPriceOrder()
    return a * b
  }

  clickPlusPackage(index) {
    this.ods.oip[index].quantClick += 1
    this.ods.changePackage()
    return 
  }  
  
  clickMinusPackage(index) {
    this.ods.oip[index].quantClick -= 1
    this.ods.changePackage()
    return 
  }

  changeTransport(index) {
    console.log("změna dopravy")
    if(!this.ods.op.boolTransport) {
      this.ods.oip[index].quantClick = 0
      this.ods.oip[index].QUANTITY = 0
    } else {
      this.ods.oip[index].quantClick = 1
      this.ods.oip[index].QUANTITY = 1
    }
    console.log("ods.oip[index].PRICE", this.ods.oip[index])
  }
}
