<div id="title-page">
  <h1 class="text-center">REKAPITULACE OBJEDNÁVKY</h1>
</div>

<div id="order-summary-customer">
  <div class="container my-2">
    <div class="row my-2">
      <div class="col-md-4"> 
        <p class="label"> Jméno,  Příjmení, Firma: </p>
        <p class="info"> {{ actCustomer.name }} </p> 
      </div>
      <div class="col-md-4"> 
        <p class="label"> Adresa: </p>
        <p class="info"> {{ actCustomer.street }} </p> 
      </div>
      <div class="col-md-4"> 
        <p class="label"> Město: </p> 
        <p class="info"> {{ actCustomer.city }} </p> 
      </div>
    </div>

    <div class="row my-2">
      <div class="col-md-4"> 
        <p class="label"> Telefon: </p>
        <p class="info"> {{ actCustomer.phone }} </p> 
      </div>
      <div class="col-md-8"> 
        <p class="label"> Email: </p>
        <p class="info"> {{ actCustomer.email }} </p> 
      </div>
    </div>
    <div class="row my-2">
      <div class="col-md-4"> 
        <p class="label"> Doručení: </p>
        <p class="info"> {{ orderCustomerDrive.NAME }} </p> 
      </div>
      <div class="col-md-4"> 
        <p class="label"> Typ platby: </p>
        <p class="info"> {{ actCustomer.customerType.description }} </p> 
      </div>
      <!-- <div class="col-md-4"> <p class="sum-info" *ngIf="actCustomer.customerType.typeId == 1 "> {{ actCustomer.customerType.description }} </p> </div> -->
    </div>
  </div>
</div>

<hr>

<div id="order-summary-items">
  <div class="container my-2">
    <ng-container *ngFor="let orderItem of orderItemsAll; index as i" class="order-items">
      <div class="row item my-3">
        <div class="col-7"> {{i+1}}) {{ orderItem.ITEMS_TITLE }} </div>
        <div class="col-2 text-right"> {{ orderItem.ITEMS_PRICE_SPECIAL }},00 Kč/ks</div>
        <div class="col-1 text-center"> {{ orderItem.QUANTITY }}ks </div>
        <div class="col-2 text-right">
          <span>∑ </span>
          <span>{{ orderItem.QUANTITY * orderItem.ITEMS_PRICE_SPECIAL }}</span>
          <span> ,00 Kč </span>
        </div>
        <div class="col-1"></div>
      </div>
    </ng-container>
  </div>
</div>


<div id="order-summary-price">
  <div class="container">
    <div class="row p-1">

      <div class="column" style="font-size: 18px;">
        <ng-container *ngIf="description">
          <div>
            Poznámka:
          </div>
          <div style="font-weight: 400;">
            {{description}}
          </div>
        </ng-container>
      </div>

      <div class="column" >
          <div>
            <div class="fl"  [ngStyle]="{'text-decoration' : orderPriceSpecialAllItems ? 'line-through' : 'none'}">Objednávka celkem:</div>
            <div class="fr"  [ngStyle]="{'text-decoration' : orderPriceSpecialAllItems ? 'line-through' : 'none'}">{{orderPriceAllItems}},00 Kč</div>
          </div>  
          
          <div *ngIf="orderPriceSpecialAllItems > 0">
            <div>
              <div class="fl">Speciální cena:</div>
              <div class="fr">{{orderPriceSpecialAllItems}},00 Kč</div>
            </div>
          </div>
      </div>
    </div>
  </div>
</div>

<div class="container mt-5 mb-3 mininav">
  <div class="row justify-content-around">
    <button class="col-3 btn btn-lg btn-secondary p-3" style="font-size: 24px; line-height: 1" (click)="urlSummaryToEdit()" > ZPĚT </button>
    <!-- <button class="col-3 btn btn-lg btn-secondary p-3" style="font-size: 24px; line-height: 1" (click)="redirect("rozcetnik")"> Zrušit objednávku </button> -->
    <!-- <button class="col-3 btn btn-lg btn-success p-3" style="font-size: 24px; line-height: 1" (click)="sendconsol()"> DOKONČIT </button> -->
    <button class="col-3 btn btn-lg btn-success p-3" style="font-size: 24px; line-height: 1" (click)="addPostOrderDetail()"> DOKONČIT </button>
  </div>
  <div class="row">
    <div class="col-8"></div>
    <div class="col-4">
      <div *ngIf="errorSend" class="chyba při odeslání">Chyba při ukládání objednávky</div>
    </div>

  </div>
</div>