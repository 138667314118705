<nav class="navbar navbar-expand navbar-dark bg-dark p-4">
    <div class="col-2">
        <a (click)="urlCreateToGuide()" class="navbar-brand" style="cursor: pointer">ROZCESTNÍK</a>
    </div>
    <div class="col-9"></div>
    <div class="col-1 text-right">
        <div class="navbar-nav text-right">
            <li class="nav-item" style="cursor: pointer">
                <a (click)="shareFuncitonS.logout()" class="nav-link">Odhlásit</a>
            </li>
        </div>
    </div>
</nav>