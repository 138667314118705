<!-- <p>order-items works!</p> -->

<div id="order-items">
  <div class="container">
    <!-- BUTTON PRO PŘIDÁNÍ -->
    <div class="row my-4">
      <button class="btn btn-lg btn-info btn-block col-8 mx-md-auto p-3" (click)="newOrderItemFood()">
        Přidat položku
      </button>
    </div>
<!-- {{ods.oif | json}} -->
    <!-- POLOŽKY JÍDEL -->
    <ng-container *ngFor="let itemsFood of this.ods.oif; index as i" class="order-items">
      <!-- {{itemsFood | json}} -->
      <div class="row order-item my-2">
        <!-- <div class="col-1">{{i}}</div> -->
        <div class="col" style="padding-left: 15px !important">
          <!-- <ng-container>
            <div class="items-name-show-text truncate-check-ellipsis" style="position: absolute; font-size: 22px; top: 11px; left: 20px; width: 450px">
                {{ods.oif[i].itemOrig.TITLE}}
            </div>
          </ng-container> -->
          <select
            name="select-orderItem"
            [(ngModel)]="ods.oif[i].itemOrig"
            (change)="changeItem(i, ods.oif[i], ods.oif[i].itemOrig)"
            [ng-reflect-model]="ods.oif[i].itemOrig.TITLE">
            <option *ngFor="let item of apiDataS.itemsFood$" [ngValue]="item">
              {{ item.TITLE }}
            </option>
          </select>
          <button [style.max-width]="itemsFood.ITEMS_TITLE ? '410px': '100%'" class="reset-button button-select truncate-check-ellipsis">{{ods.oif[i].itemOrig.TITLE}}</button>
        </div>

        <ng-container *ngIf="itemsFood.ITEMS_TITLE">
          <div class="col-2 text-center">
            <input 
              type="number"
              style="text-align: right"
              [(ngModel)]="itemsFood.ITEMS_PRICE_SPECIAL">
            ,00 Kč/ks
          </div>

          <div class="col-2">
            <div class="row nomargin">
              <div class="col-4">
                <button
                  type="button"
                  class="button-own"
                  (click)='clickMinus(itemsFood)'>
                  -
                </button>
              </div>  
              <div class="col-4">
                <input
                  type="number"
                  [(ngModel)]="itemsFood.QUANTITY">
              </div>
              <div class="col-4">
                <button 
                  type="button" 
                  class="button-own" 
                  (click)='clickPlus(itemsFood)'
                  min="0">
                  +
                </button>
              </div>
            </div>
          </div>
          <div class="col-2 text-right sum" style="position: relative;">
            <div class="tooltipBalance"
              [style.color]="itemsFood.itemOrig.balance-itemsFood.QUANTITY > 5 ? 'black' : 'red'" 
              data-tooltip="AKTUÁLNÍ stav jídel">
              {{ itemsFood.itemOrig.balance - itemsFood.QUANTITY}}
            </div>
            <span>∑</span>
            <input 
              type="number"
              style="text-align: right"
              [value]="getSum(itemsFood.ITEMS_PRICE_SPECIAL, itemsFood.QUANTITY)"
              disabled>
              ,00 Kč
          </div>

          <!-- <div class="col-2" style="text-align:center">
            <div class="text-item">
              ∑ {{orderItem.ITEMS_PRICE_SPECIAL*orderItem.QUANTITY}},00 Kč
            </div>
          </div> -->

          <div class="col-1 text-center">
            <button 
              type="button" 
              class="button-own button-delete" 
              (click)='delete(this.ods.oif[i])'>
                X
            </button>
          </div>

        </ng-container>
      </div>
    </ng-container>

  </div>
</div>
