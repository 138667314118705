import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { OrderDataService } from 'src/app/pages/order/order-data.service';
import { StorageService } from 'src/app/service/storage.service';

@Component({
  selector: 'app-order-button',
  templateUrl: './order-button.component.html',
  styleUrls: ['./order-button.component.scss']
})
export class OrderButtonComponent implements OnInit {
  @Input() pageName: string

  constructor(
    private router: Router,
    public ods: OrderDataService,
    private storageMemory: StorageService
    ) { }

  ngOnInit(): void { }

  
  urlCreateToGuide() {
    this.ods.oif = [];
    this.ods.oip = [];
    this.storageMemory.resetOrderDataLS();
    this.router.navigateByUrl('/guide')
  }

  urlCreateToSummary() {
    this.storageMemory.saveOrderAllLS(this.ods.oc, this.ods.oif, this.ods.oip, this.ods.op);
    this.storageMemory.createDataForDetailOrder();
    
    this.storageMemory.createLocalStorageData()
    // localStorage.setItem('orderPriceSpecialAllItems', String(this.orderPriceSpecialAllItems | 0));
    // localStorage.setItem('description', this.description);
    this.router.navigateByUrl('/order/summary')
  }

}
