<!-- <p>order works!</p> -->
<!-- {{orderDataService.testdat}} -->

<a routerLink="new">new</a>
<a routerLink="neww">neww</a>
<a routerLink="summary">summary</a>
<a routerLink="detail">detail</a>

<div id="title-page">
    <h1 class="text-center">HLAVNÍ NADPIS DLE URL</h1>
</div>

<hr>
<button (click)="orderDataService.zmenit('info hlavni order')">BUTTON</button>
<hr>


<router-outlet></router-outlet>
