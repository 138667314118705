import { Directive, ElementRef, Input, OnInit } from '@angular/core';


@Directive({
  selector: '[appCodebookDriver]'
})
export class CodebookDriverDirective implements OnInit {
  @Input() customerTypeId: string;


  constructor(private el: ElementRef) {
  }


  ngOnInit() {
      // this.backgroundSupportProcess(this.supportProcess);
      this.backgroundState(this.customerTypeId);
  }

  backgroundSupportProcess(supportProcess) {
      switch (supportProcess) {
          case '1406': return this.el.nativeElement.style.backgroundColor = '#e6f2ff'; // HMPD-1270: Kontrola splnění podmínek - interakce s klientem
      }
  }

  backgroundState(customerTypeId) {
      switch (customerTypeId) {
          case '1': return this.el.nativeElement.style.backgroundColor = '#ecffe6'; // splněna
          case '2': return this.el.nativeElement.style.backgroundColor = '#ffefef'; // nesplněna
          case '3': return this.el.nativeElement.style.backgroundColor = '#ffefef'; // nesplněna
          case '4': return this.el.nativeElement.style.backgroundColor = '#ffefef'; // nesplněna
          case '5': return this.el.nativeElement.style.backgroundColor = '#ffefef'; // nesplněna
          case '6': return this.el.nativeElement.style.backgroundColor = '#ffefef'; // nesplněna
          case '7': return this.el.nativeElement.style.backgroundColor = '#ffefef'; // nesplněna
          case '8': return this.el.nativeElement.style.backgroundColor = '#ffefef'; // nesplněna
          // case 'READY_FOR_VERIFICATION': return this.el.nativeElement.style.backgroundColor = '#fff7e6'; // k ověření
          // case 'IN_VERIFICATION': return this.el.nativeElement.style.backgroundColor = '#f1fcf6'; // probíhá ověření
          case '': return;
      }
      return;
  }

}

