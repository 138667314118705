import { Component, OnInit } from '@angular/core';
import { OrderDataService } from 'src/app/pages/order/order-data.service';
import { ApiDataService } from 'src/app/service/api-data.service';

@Component({
  selector: 'app-order-price',
  templateUrl: './order-price.component.html',
  styleUrls: ['./order-price.component.scss']
})
export class OrderPriceComponent implements OnInit {

  loadOrderPrice = JSON.parse(localStorage.getItem('orderPrice')) || null

  constructor(    
    public apiDataS: ApiDataService,
    public ods: OrderDataService
  ) { }

  ngOnInit(): void {
    if(this.loadOrderPrice) {
      this.ods.op.description = this.loadOrderPrice.description
      this.ods.op.priceSpecialAllItems = this.loadOrderPrice.priceSpecialAllItems
    }
  }

  saveToLocal(description) {
    console.log("změna poznámky")
    console.log(description)
    localStorage.setItem('description',description)
  }
  
}
