import { Component, OnInit } from '@angular/core';
import { OrderDataaService } from './order-data.service';

@Component({
  selector: 'app-order-tmp',
  templateUrl: './order-tmp.component.html',
  styleUrls: ['./order-tmp.component.scss']
})
export class OrderTmpComponent implements OnInit {

  constructor(public orderDataService: OrderDataaService) {
    orderDataService.consolo()
   }

  ngOnInit(): void {
  }

}
