import { ApiDataService } from 'src/app/service/api-data.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { OrderDataService } from 'src/app/pages/order/order-data.service';
import { DataService } from 'src/app/service/data.service';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-order-items',
  templateUrl: './order-items.component.html',
  styleUrls: ['./order-items.component.scss']
})
export class OrderItemsComponent implements OnInit {
  // priceAllItems = 0;  
  // orderPriceSpecialAllItems;  
  // description = "";

  // selectItem = this.apiDataS.itemsFood$;



  constructor( 
    public dataS: DataService,
    public apiDataS: ApiDataService,
    public ods: OrderDataService
  ) {	
    // localStorage.setItem('orderCustomerTypeId', "0");
    // this.loadCustomerLocal();
    // this.ods.oif = [];
  }
  
  ngOnInit(): void {
  }

  // TODO: změnit QUANTITY na PCS
  newOrderItemFood() {
    this.ods.oif.push({
      "ITEM_ID": 0,
      "ITEMS_TITLE": "",
      "ITEMS_PRICE_SPECIAL": 0,
      "QUANTITY": 0,
      "itemOrig": {}
    })
  }
  
  changeItem(i, itemFood, itemFoodOrig) {
    itemFood.ITEM_ID = +itemFoodOrig.ITEM_ID
    itemFood.ITEMS_TITLE = itemFoodOrig.TITLE
    itemFood.ITEMS_PRICE_SPECIAL = +itemFoodOrig.PRICE
    itemFoodOrig.balance = +itemFoodOrig.QUANTITY || 0
    this.ods.storageMemory.saveOrderAllLS(this.ods.oc, this.ods.oif, this.ods.oip, this.ods.op)
  }

  clickPlus(itemsFood) {
    itemsFood.QUANTITY += 1
    this.ods.changeFood();
    return 
  }  
  
  clickMinus(itemsFood) {
    itemsFood.QUANTITY > 0 ? 
      itemsFood.QUANTITY -= 1 : 
      itemsFood.QUANTITY
    this.ods.changeFood();
    return 
  }  

  delete(itemDelete) {
    this.ods.oif = this.ods.oif.filter(orderItem => orderItem !==itemDelete);
    this.ods.changeFood();
    return 
  }

  getSum(a, b) {
    this.ods.changeFood();
    return a * b
  }
  
}
