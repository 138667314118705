import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'src/app/service/api.service';

interface ORDER_INFO {
    "ORDER_ID": string,
    "CUSTOMER_ID": string,
    "DRIVER_ID": string,
    "CREATED_AT": string,
    "CREATED_BY": string,
    "LASTEDITED_AT": string,
    "LASTEDITED_BY": string,
    "DESCRIPTION": string,
    "PRICE": string,
    "DELETED": string,
    "PRICE_SPECIAL": string,
    "ORDER_CUSTOMER":{
      "CUSTOMER_ID": string,
      "NAME": string,
      "STREET": string,
      "CITY": string,
      "PHONE": string,
      "EMAIL": string,
      "CUSTOMER_TYPE_ID": string,
      "CUSTOMER_TYPE_DESCRIPTION": string
    },
    "ORDER_ITEMS":[{
      "ITEM_ID": string,
      "ITEMS_TITLE": string,
      "QUANTITY": string,
      "ITEMS_PRICE": string,
      "ITEMS_PRICE_SPECIAL": string
    }]
  }

@Component({
  selector: 'app-order-detail-tmp',
  templateUrl: './order-detail-tmp.component.html',
  styleUrls: ['./order-detail-tmp.component.scss']
})
export class OrderDetailTmpComponent implements OnInit {
  id: string;
  oi:ORDER_INFO;

  constructor(public apiS: ApiService, private route: ActivatedRoute, private router: Router) {
    this.id = this.route.snapshot.paramMap.get('id');
    this.callOrderDetail(this.id)
   }

  ngOnInit() {
  }

  callOrderDetail(number) {
    this.apiS.getOrderDetail(number).subscribe(
      data => {
        console.log(data)
        this.oi = data.ORDER_INFO
        // this.oc = data.ORDER_INFO.ORDER_CUSTOMER
        // this.it = data.ORDER_INFO.ORDER_ITEMS
      },
      error => console.error(error)
    )
  }

  urlSummaryToList() {
    this.router.navigateByUrl('/web-list')
  }

  urlDeleteToList() {
    console.log("delete číslo:", this.id)

    let myHeaders = new Headers();
    myHeaders.append("Login", "api@jirka.cz");
    myHeaders.append("Password", "Smazakpodebradsky");

    let requestOptions = {
      method: 'DELETE',
      headers: myHeaders,
    };

    let url = `https://vojtas.plasanci.cz/API/ORDERS.php?ID=${this.id}`

    fetch(url, requestOptions)
      .then(response => response.text())
      .then(result => this.urlSummaryToList())
      .catch(error => console.log('error', error));     

    // console.log("delete číslo:", this.id)
    // this.apiS.delOrder(this.id);
  }

}
