<!-- <p>order-price works!</p> -->
{{ods.op | json}}

<div id="order-sum">
    <div class="container">
        <div class="row p-1">
            <div class="column">
                <div class="pos-rel editable-area">
                    <div class="name-editable"> Poznámka: </div>
                    <div contenteditable="true" class="editable" [textContent]="ods.op.description"  (input)="ods.op.description=$event.target.textContent; saveToLocal(ods.op.description)"></div>
                </div>
            </div>

            <div class="column summary">
                <div class="row" [ngStyle]="{'text-decoration' : ods.op.priceSpecialAllItems ? 'line-through' : 'none'}">
                    <div class="col-6">Objednávka celkem:</div>
                    <div class="col-4 text-right">{{ods.op.priceAllItems}},00 Kč</div>
                    <div class="col-2"></div>
                </div>
        
                <div class="row">
                    <div class="col-6">Speciální cena:</div>
                    <div class="col-4 text-right">
                        <input
                        [ngStyle]="{'width': ods.op.priceSpecialAllItems > 0 ? '50%' : '100%'}"
                        id="price-special"
                        type="number"
                        [(ngModel)]="ods.op.priceSpecialAllItems">
                        <span *ngIf="ods.op.priceSpecialAllItems">,00Kč</span></div>
                    <div class="col-2"></div>
                </div>
                
                <div class="row">
                    <ng-container *ngIf="ods.oc.customerType.CUSTOMER_TYPE_ID == '1'">
                        <div class="col-6">Zůstatek kreditu:</div>
                        <div class="col-4 text-right">{{ods.sumCredit()}},00 Kč</div>
                        <div class="col-2"></div>
                    </ng-container>
                </div>
            
            </div>  
        </div>
    </div>
</div> 
                