<app-menu></app-menu>

<div id="title-page">
    <h1 class="text-center">Přijaté objednávky</h1>
</div>   
<div id="web-list">

     <table class="table table-striped">
        <thead>
            <tr>
                <th scope="col">Zákazník</th>
                <th scope="col" class="text-center">Datum objednávky</th>
                <th scope="col" class="text-right">Celková částka</th>
                <th scope="col" class="text-right">Speciální cena</th>
                <th scope="col"></th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let order of dataS.orders$; index as i">
                <!-- <th scope="row"> {{ i + 1 }} </th> -->
                <td> {{ order.CUST_NAME }} </td>
                <!-- <td> {{ order.date | date: 'dd. MM. yyyy'}} </td> -->
                <td class="text-center"> {{ order.CREATED_AT  }} </td>
                <td class="text-right"> {{ order.PRICE }}Kč </td>
                <td class="text-right"> {{ order.PRICE_SPECIAL }}Kč </td>
                <td class="text-right" style="padding: 0.75rem !important;">
                    <button class="button-open" (click)="urlListToDetail(order.ORDER_ID)"><span>Detail </span></button>
                </td>
            </tr>
        </tbody>
    </table>
</div>