import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DataService } from 'src/app/service/data.service';


@Component({
  selector: 'app-rozcestnik-tmp',
  templateUrl: './rozcestnik-tmp.component.html',
  styleUrls: ['./rozcestnik-tmp.component.scss']
})
export class RozcestnikTmpComponent implements OnInit {

  constructor(
    private router: Router,
  ) { }

  ngOnInit(): void {
  }

  variables = [
    {
      name: "Nová objednávka",
      url: "order-new",
      api: "callGetOrders"
    },
    {
      name: "Seznam objednávek",
      url: "web-list",
      api: "callGetOrders"
    },
    {
      name: "Administrace",
      url: "admin",
      api: "callGetOrders"
    },
    {
      name: "Reporty",
      url: "report",
      api: "callGetOrders"
    }
  ];

  redirect(url, api?) {
    `this.dataS.${api}LOGIN.php`;
    this.router.navigateByUrl('/' + url);
  }

}
