import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StorageService {
 
  storageData = {
    customer: {},
    orderItemsFood: [],
    orderItemsPackage: [],
    orderItemsAll:  [],
    orderPrice: {},
    orderInfo: {},
    detailOrder: {}
  }

  localStorageData = {
    customer: [],
    orderItemsFood: [],
    orderItemsPackage: [],
    orderItemsAll:  [],
    orderPrice: {},
    orderInfo: {},
    detailOrder: {}
  }


  constructor() { this.createLocalStorageData() }

  createLocalStorageData() {
    this.localStorageData.customer = JSON.parse(localStorage.getItem('customer'));
    console.log('StorageService -> constructor -> this.localStorageData.customer', this.localStorageData.customer);
    this.localStorageData.orderItemsFood = JSON.parse(localStorage.getItem('orderItemsFood'));
    console.log('StorageService -> constructor -> this.localStorageData.orderItemsFood', this.localStorageData.orderItemsFood);
    this.localStorageData.orderItemsPackage = JSON.parse(localStorage.getItem('orderItemsPackage'));
    console.log('StorageService -> constructor -> this.localStorageData.orderItemsPackage', this.localStorageData.orderItemsPackage);
    this.localStorageData.orderItemsAll = JSON.parse(localStorage.getItem('orderItemsAll'));
    console.log('StorageService -> constructor -> this.localStorageData.orderItemsAll', this.localStorageData.orderItemsAll);
    this.localStorageData.orderPrice = JSON.parse(localStorage.getItem('orderPrice'));
    console.log('StorageService -> constructor -> this.localStorageData.orderPrice', this.localStorageData.orderPrice);
    this.localStorageData.orderInfo = JSON.parse(localStorage.getItem('orderInfo'));
    console.log('StorageService -> constructor -> this.localStorageData.orderInfo', this.localStorageData.orderInfo);
    this.localStorageData.detailOrder = JSON.parse(localStorage.getItem('detailOrder'));
    console.log('StorageService -> constructor -> this.localStorageData.detailOrder', this.localStorageData.detailOrder);
  
  }
  resetAllData(){
    sessionStorage.clear()
  }

  resetOrderDataLS() {
    localStorage.removeItem('customer');
    localStorage.removeItem('orderItemsFood');
    localStorage.removeItem('orderItemsPackage');
    localStorage.removeItem('orderItemsAll');
    localStorage.removeItem('orderPrice');
    localStorage.removeItem('detailOrder');
    localStorage.removeItem('description');
    localStorage.removeItem('changeLS');
  }

  saveCustomerLS(ocOrderCustomer) {
    this.storageData.customer = ocOrderCustomer
    localStorage.setItem('customer', JSON.stringify(this.storageData.customer)) // JSON.parse(localStorage.getItem('user'));
  }

  saveItemLS(oifOrderItemsFood, oipOrderItemsPackages){
    this.storageData.orderItemsFood = oifOrderItemsFood
    this.storageData.orderItemsPackage = oipOrderItemsPackages
    this.storageData.orderItemsAll = [...oifOrderItemsFood, ...oipOrderItemsPackages]

    localStorage.setItem('orderItemsFood', JSON.stringify(this.storageData.orderItemsFood)) // JSON.parse(localStorage.getItem('user'));
    localStorage.setItem('orderItemsPackage', JSON.stringify(this.storageData.orderItemsPackage)) // JSON.parse(localStorage.getItem('user'));
    localStorage.setItem('orderItemsAll', JSON.stringify(this.storageData.orderItemsAll)) // JSON.parse(localStorage.getItem('user'));
  }

  savePriceLS(opOrderPrice){
    this.storageData.orderPrice = opOrderPrice
    localStorage.setItem('orderPrice', JSON.stringify(opOrderPrice)) // JSON.parse(localStorage.getItem('user'));
  }

  // this.oc, this.oif, this.oip, this.oia, this.op
  saveOrderAllLS(ocCustomer, oifOrderItemsFood, oipOrderItemsPackages, opOrderPrice) {
    this.saveCustomerLS(ocCustomer)
    this.saveItemLS(oifOrderItemsFood, oipOrderItemsPackages)
    this.savePriceLS(opOrderPrice)
    this.createDataForDetailOrder()
    let changeLS = +localStorage.getItem("changeLS") + 1
    localStorage.setItem("changeLS", String(changeLS))
  } 

  saveOrderDetail(ddbDetailDatabase) {
    this.storageData.detailOrder = ddbDetailDatabase;
    localStorage.setItem('detailOrder', JSON.stringify(this.storageData.detailOrder)) // JSON.parse(localStorage.getItem('user'));
  }
  
  createDataForDetailOrder() {console.info("vytvoříme data pro uložení")
    // console.log('StorageService -> createDataForDetailOrder -> orderItemsAll', orderItemsAll);
    console.log('StorageService -> createDataForDetailOrder -> this.storageData.orderPrice', this.storageData.orderPrice);
    console.log('StorageService -> createDataForDetailOrder -> this.storageData.customer', this.storageData.customer);
    let orderInfo = {
      "ORDER_INFO": {
          "ORDER_ID": (this.storageData.orderPrice as any).ORDER_ID,
          "CUSTOMER_ID": (this.storageData.customer as any).CUSTOMER_ID,
          "DRIVER_ID": (this.storageData.customer as any).driver.DRIVER_ID,
          "DESCRIPTION": (this.storageData.orderPrice as any).description || "",
          "PRICE": (this.storageData.orderPrice as any).priceAllItems,
          "PRICE_SPECIAL": (this.storageData.orderPrice as any).priceSpecialAllItems,
          "ORDER_CUSTOMER": {
              "CUSTOMER_ID": (this.storageData.customer as any).CUSTOMER_ID,
              "NAME": (this.storageData.customer as any).NAME,
              "STREET": (this.storageData.customer as any).STREET,
              "CITY": (this.storageData.customer as any).CITY,
              "PHONE": (this.storageData.customer as any).PHONE,
              "EMAIL": (this.storageData.customer as any).EMAIL,
              "CUSTOMER_TYPE_ID": (this.storageData.customer as any).customerType.CUSTOMER_TYPE_ID,
              "CUSTOMER_TYPE_DESCRIPTION": (this.storageData.customer as any).customerType.DESCRIPTION,
              "CREDIT": (this.storageData.customer as any).CREDIT,
          },
          "driver": {
            "DRIVER_ID": (this.storageData.customer as any).driver.DRIVER_ID,
            "NAME": (this.storageData.customer as any).driver.NAME,
          },
          "ORDER_ITEMS": this.storageData.orderItemsAll
      }
  }
    console.log('SessionStorageService -> createDataForDetailOrder -> orderInfo',  orderInfo);
    this.saveOrderDetail(orderInfo)
    return
  }  
  
  createDataForSaveOrder(){ console.log("vytvoříme data pro zobrazení detailu")
    const orderPrice = JSON.parse(localStorage.getItem('orderPrice'))
    const customer = JSON.parse(localStorage.getItem('customer'))
    const orderItemsAll = JSON.parse(localStorage.getItem('orderItemsAll'))
    
    let ORDER_INFO = {
      ORDER_ID: orderPrice.ORDER_ID,
      CUSTOMER_ID: customer.CUSTOMER_ID,
      CREATED_BY: localStorage.getItem('USERID'),
      DESCRIPTION: orderPrice.description,
      DRIVER_ID: customer.driver.DRIVER_ID,	
      PRICE: orderPrice.priceAllItems,
      PRICE_SPECIAL: orderPrice.priceSpecialAllItems,
      ORDER_ITEMS: this.storageData.orderItemsAll,
    }
    return ORDER_INFO
  }
  
  createDataFromDB(dataApi) {
    console.log('StorageService -> createDataFromDB -> dataApi', dataApi);
    this.createOC(dataApi);
    this.createOP(dataApi)
    this.createOIA(dataApi)
    this.createDataForDetailOrder()
  }

  createOC(dataDB) {
    console.log('StorageService -> createOC -> dataDB', dataDB);
    const oc = { 
      CUSTOMER_ID: dataDB.ORDER_INFO.ORDER_CUSTOMER.CUSTOMER_ID,
      NAME: dataDB.ORDER_INFO.ORDER_CUSTOMER.NAME, 
      STREET: dataDB.ORDER_INFO.ORDER_CUSTOMER.STREET, 
      CITY: dataDB.ORDER_INFO.ORDER_CUSTOMER.CITY, 
      PHONE: dataDB.ORDER_INFO.ORDER_CUSTOMER.PHONE, 
      EMAIL: dataDB.ORDER_INFO.ORDER_CUSTOMER.EMAIL, 
      CREDIT: +dataDB.ORDER_INFO.ORDER_CUSTOMER.CREDIT, 
      driver: { DRIVER_ID: +dataDB.ORDER_INFO.DRIVER_ID, NAME: dataDB.ORDER_INFO.DRIVER_NAME}, 
      customerType: { CUSTOMER_TYPE_ID: +dataDB.ORDER_INFO.ORDER_CUSTOMER.CUSTOMER_TYPE_ID, DESCRIPTION: dataDB.ORDER_INFO.ORDER_CUSTOMER.CUSTOMER_TYPE_DESCRIPTION }
    
  
    }
    console.log('StorageService -> createOC -> oc', oc);
    this.saveCustomerLS(oc)
    return
  }  
  
  createOI() {

  }  
    
  createOP(dataDB) {
    let startCredit = 
    dataDB.ORDER_INFO.ORDER_CUSTOMER.CUSTOMER_TYPE_ID != 1 ? 
        0 :
        dataDB.ORDER_INFO.PRICE_SPECIAL ? 
          +dataDB.ORDER_INFO.PRICE_SPECIAL+dataDB.ORDER_INFO.ORDER_CUSTOMER.CREDIT :
          +dataDB.ORDER_INFO.PRICE+dataDB.ORDER_INFO.ORDER_CUSTOMER.CREDIT

    let transport = dataDB.ORDER_INFO.ORDER_ITEMS.map(item => item.ITEM_ID == 3843 && item.ITEMS_PRICE_SPECIAL > 0 ? true : false)
    let transBool = transport.some(this.checkBool)


    const op = {
      priceAllItems: +dataDB.ORDER_INFO.PRICE || 0,
      priceSpecialAllItems: +dataDB.ORDER_INFO.PRICE_SPECIAL || 0,
      credit: startCredit || 0,
      boolTransport: transBool || false,
      // priceTransport: 0,
      description: dataDB.ORDER_INFO.DESCRIPTION ||  localStorage.getItem('description') || "",
      ORDER_ID: dataDB.ORDER_INFO.ORDER_ID || ""
    }

    console.log('StorageService -> createOP -> op', op);
    this.savePriceLS(op)
    return
  }

  createOIA(dataDB) {
    this.storageData.orderItemsAll = dataDB.ORDER_INFO.ORDER_ITEMS
    localStorage.setItem('orderItemsAll', JSON.stringify(this.storageData.orderItemsAll)) // JSON.parse(localStorage.getItem('user'));
    console.log('StorageService -> createOIA -> this.storageData.orderItemsAll', this.storageData.orderItemsAll);
    // this.savePriceLS(this.storageData.orderItemsAll)

  }

  checkBool(bool) {
    return bool == true
  }
  // createDataForDetailOrder(){ console.log("vytvoříme data pro zobrazení detailu") }


  /**
   * EXAMPLE: 
   * https://www.w3schools.com/jsref/prop_win_sessionstorage.asp
   * https://developer.mozilla.org/en-US/docs/Web/API/Window/sessionStorage
   * https://developer.mozilla.org/en-US/docs/Web/API/Web_Storage_API/Using_the_Web_Storage_API
   */
  // Save data to Storage: sessionStorage.setItem('key', 'value');
  // Load data from Storage: this.variables = sessionStorage.getItem('key');
  // Remove: sessionStorage.removeItem('key'); sessionStorage.clear()
  // AutoSave: if (sessionStorage.getItem('key')) { this.variables = sessionStorage.getItem('key')
  // Poslouchač: this.variables.addEventListener('change', () => {sessionStorage.setItem('key', this.variables);})
  // JSON save to Storage: localStorage.setItem(key, JSON.stringify('value'))
  // JSON load to Storage: this.variables = JSON.parse(localStorage.getItem('key'))
}
