import { Injectable, TemplateRef } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class ToastService {
  toasts: any[] = [];



  show(textOrTpl: string | TemplateRef<any>, options: any = {}) {
    this.toasts.push({ textOrTpl, ...options });
    setTimeout(()=>{
      this.remove(this.toasts[0]);
    },options.delay); 
  }

  remove(toast) {
    console.log(toast)
    this.toasts = this.toasts.filter(t => t !== toast);
  }

  /**
   * Voláme v componenta.ts následovně:
  showStandard() {
    this.toastService.show('I am a standard toast');
  }

  showSuccess() {
    this.toastService.show('I am a success toast', { classname: 'bg-success text-light', delay: 1000 });
  }

  showDanger(dangerTpl) {
    this.toastService.show(dangerTpl, { classname: 'bg-danger text-light', delay: 15000 });
  }
  */
}