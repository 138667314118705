import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ILoginRes } from '../models/ilogin';
import { IUser } from '../models/IUser';

const baseUrl:string = 'https://vojtas.plasanci.cz/API';

interface IHeaderBody {
  LOGIN: string,
  PASSWORD: string,
  USERID?: string
}

@Injectable({
  providedIn: 'root'
})

export class ApiCallService {
  public user: Observable<ILoginRes>;
  myheader;

  constructor( private http: HttpClient ) { this.loginCreate() }

  getAll(): Observable<any> {
    return this.http.get(baseUrl);
  }

  /*loginCreate() {
    this.myheader = new HttpHeaders()
      .set('LOGIN', localStorage.getItem('LOGIN'))
      .set('PASSWORD', localStorage.getItem('PASSWORD'))
      .set('USERID', localStorage.getItem('USERID'))
  } */

  loginCreate() {
    this.myheader = new HttpHeaders()
       .set('LOGIN', "api@jirka.cz")
       .set('PASSWORD', "Smazakpodebradsky")
       .set('USERID', "1")
  } 

  /** 
   * LOGIN 
  */
  // Přihlášení do APPky
  login(username, password) {
    localStorage.setItem('LOGIN', username);
    localStorage.setItem('PASSWORD', password);
    this.myheader = new HttpHeaders()
      .set('LOGIN', localStorage.getItem('LOGIN'))
      .set('PASSWORD', localStorage.getItem('PASSWORD'));
      return this.http.get<IUser>(`${baseUrl}/LOGIN.php`, { headers: this.myheader })
  }

  /** 
   * CUSTOMERS, CUSTOMER_TYPE
   */
  // Dostanu všechny zákazníky
  getCustomers():Observable<any> {
    return this.http.get(`${baseUrl}/CUSTOMERS.php`, { headers: this.myheader })  
  }

  // Dostanu konkrétního zákazníka podle id
  getCustomer(id):Observable<any> {
    return this.http.get(`${baseUrl}/CUSTOMERS.php?ID=${id}`, { headers: this.myheader })  
  }   
  
  // Dostanu konkrétního zákazníka podle id
  getCustomerTypes():Observable<any> {
    return this.http.get(`${baseUrl}/CUSTOMER_TYPE.php`, { headers: this.myheader })  
  }    
  
  // Dostanu konkrétního zákazníka podle id
  getCustomerType(id):Observable<any> {
    return this.http.get(`${baseUrl}/CUSTOMER_TYPE.php?ID=${id}`, { headers: this.myheader })  
  }  
  
  // Založí nového customer
  postCustomer(formdata):Observable<any> {
    return this.http.post(`${baseUrl}/CUSTOMERS.php`, formdata, { headers: this.myheader })  
  }  
  
  // Změna dat u stávajícího customer
  putCustomer(formdata):Observable<any> {
    return this.http.put(`${baseUrl}/CUSTOMERS.php`, formdata, { headers: this.myheader })  
  }

  /** 
   * DRIVERS 
   */
  // Dostanu všechny způsoby dodání
  getDrivers(): Observable<any> {
    return this.http.get(`${baseUrl}/DRIVERS.php`, { headers: this.myheader });
  }  
  
  // Dostanu konkrétní jeden způsob dodání
  getDriver(id): Observable<any> {
    return this.http.get(`${baseUrl}/DRIVERS.php?ID=${id}`, { headers: this.myheader });
  }


  /** 
   * USERS, USER_ROLE
   */
  // Dostanu všechny uživatele
  getUsers():Observable<any> {
    return this.http.get(`${baseUrl}/USERS.php`, { headers: this.myheader })  
  }

  // Dostanu konkrétního uživatele podle id
  getUser(id):Observable<any> {
    return this.http.get(`${baseUrl}/USERS.php?ID=${id}`, { headers: this.myheader })  
  }

  /** 
   * ITEMS, ITEM_TYPE
   */
  // Dostanu všechny jídla ke konkrétnímu dni
  getItems():Observable<any> {
    return this.http.get(`${baseUrl}/ITEMS.php`, { headers: this.myheader })  
  }

  // Dostanu konkrétní jedno jídlo
  getItem(id):Observable<any> {
    return this.http.get(`${baseUrl}/ITEMS.php?ID=${id}`, { headers: this.myheader })  
  }   
   
  // Dostanu všechny kategorie jídel
  getItemsType():Observable<any> {
    return this.http.get(`${baseUrl}/ITEM_TYPE.php`, { headers: this.myheader })  
  }    
  
  // Dostanu konkrétní kategorie jídla
  getItemType(id):Observable<any> {
    return this.http.get(`${baseUrl}/ITEM_TYPE.php?ID=${id}`, { headers: this.myheader })  
  }  



  /** 
   * ORDERS
   */
  // Dostanu všechny jídla ke konkrétnímu dni
  getOrders():Observable<any> {
    return this.http.get(`${baseUrl}/ORDERS.php`, { headers: this.myheader })  
  }

  // Dostanu konkrétní jedno jídlo
  getOrder(id):Observable<any> {
    return this.http.get(`${baseUrl}/ORDERS.php?ID=${id}`, { headers: this.myheader })  
  }  

  delOrder(id):Observable<any> {
    return this.http.delete(`${baseUrl}/ORDERS.php?ID=${id}`, { headers: this.myheader })  
  }  
  
  /** 
   * ORDERS_DETAIL
   */
  // Dostanu všechny jídla ke konkrétnímu dni
  getOrdersDetail():Observable<any> {
    return this.http.get(`${baseUrl}/ORDER_DETAIL.php`, { headers: this.myheader })  
  }

  // Dostanu konkrétní jedno jídlo
  getOrderDetail(id):Observable<any> {
    return this.http.get(`${baseUrl}/ORDER_DETAIL.php?ID=${id}`, { headers: this.myheader })  
  }

  // ULOŽÍ novou objednávku

}
