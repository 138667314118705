<!-- <p>order-items-packages works!</p> -->
 <!-- OBAL K JÍDLu -->
<div id="order-items-packages">
    <div class="container my-4">
      <ng-container *ngFor="let itemPackage of this.ods.oip; index as ind" class="order-items">
        <div class="row order-item">

          <ng-container *ngIf="itemPackage.itemOrig.ITEM_TYPE_ID != 75">
            <div class="col-3 ml-auto">
              <p class="text-item m-0 p-2">{{itemPackage.ITEMS_TITLE}}</p> 
            </div>          
            <div class="col-2 txt-center">
              <input 
                type="number"
                style="text-align: right"
                [(ngModel)]="itemPackage.ITEMS_PRICE_SPECIAL">
                ,00 Kč/ks
            </div>
            <div class="col-2">
              <div class="row nomargin">
                <div class="col-4">
                  <button type="button" class="button-own" (click)='clickMinusPackage(ind)'> 
                    - 
                  </button>
                </div>  
                <div class="col-4">
                  <input
                    type="number"
                    [(ngModel)]="itemPackage.QUANTITY" 
                    disabled>
                </div>
                <div class="col-4">
                  <button type="button" class="button-own" (click)='clickPlusPackage(ind)'>
                    +
                  </button>
                </div>
              </div>
            </div>
            <div class="col-2 text-right sum" style="position: relative">
              <span>∑</span>
              <input 
                type="number"
                style="text-align: right"
                [value]="getSum(itemPackage.ITEMS_PRICE_SPECIAL, itemPackage.QUANTITY)"
                disabled>
                ,00 Kč
            </div>
          </ng-container>

          <ng-container *ngIf="itemPackage.itemOrig.ITEM_TYPE_ID == 75">
            <div class="col-2">
              <div class="text-center">
                <input type="checkbox" class="price-transport" 
                [(ngModel)]="ods.op.boolTransport"
                (change)="changeTransport(ind)">
              </div>
            </div>
            <div class="col-3">
              <p class="text-item m-0 p-2">{{itemPackage.ITEMS_TITLE}}</p> 
            </div>
      
            <ng-container *ngIf="ods.op.boolTransport; else tmpTransportFree">
              <div class="col-2 txt-center">
              <input 
                type="number"
                style="text-align: right"
                [(ngModel)]="itemPackage.ITEMS_PRICE_SPECIAL">
                ,00 Kč/ks
            </div>
          
            <div class="col-2">
              <div class="row nomargin">
                <div class="col-4">
                  <button type="button" class="button-own" (click)='clickMinusPackage(ind)'> 
                    - 
                  </button>
                </div>  

                <div class="col-4">
                  <input
                    type="number"
                    [(ngModel)]="itemPackage.QUANTITY" 
                    disabled>
                </div>
              
                <div class="col-4">
                  <button type="button" class="button-own" (click)='clickPlusPackage(ind)'>
                    +
                  </button>
                </div>
              </div>
            </div>
          
              <div class="col-2 text-right sum" style="position: relative">
                <span>∑</span>
                <input 
                  type="number"
                  style="text-align: right"
                  [value]="getSum(itemPackage.ITEMS_PRICE_SPECIAL, itemPackage.QUANTITY)"
                  disabled>
                  ,00 Kč
              </div>
            </ng-container>

          </ng-container>
        <div class="col-1"> </div>
      </div>
    </ng-container>
  </div>
</div>


<ng-template #tmpTransportFree>
  <div class="col-6">
    <div class="text-logistik">
      <p>doprava zdarma</p>
    </div>
  </div>
</ng-template>