import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/service/api.service';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-login-tmp',
  templateUrl: './login-tmp.component.html',
  styleUrls: ['./login-tmp.component.scss'],
})
export class LoginTmpComponent implements OnInit {
  username: string;
  password: string;

  form: FormGroup;
  loading = false;
  submitted = false;

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private apiService: ApiService    
  ) {}

  ngOnInit() {
    this.form = this.formBuilder.group(
      {
        username: ['', Validators.required],
        password: ['', Validators.required]
      }
    );
  }

  get f() { return this.form.controls; }

  onSubmit() {
    this.submitted = true;

    // reset alerts on submit
    // this.alertService.clear();

    // stop here if form is invalid
    if (this.form.invalid) {
        return;
    }

    this.loading = true;
    this.apiService.login(this.f.username.value, this.f.password.value).subscribe(
      data => {
        console.log(data);
        if(data.ERROR_CODE == 400) return this.loading = false;
        localStorage.setItem("USERID", data.USERID);
        this.router.navigateByUrl('/guide');
      },
      error => {
        console.log(error);
          // this.alertService.error(error);
          this.loading = false;
      }
    );
}



  loginForm() {
    console.log("Ahoooj");
  }


  // this.apiService.login(data)
  //   .subscribe(
  //     response => {
  //       console.log(response);
  //       this.submitted = true;
  //     },
  //     error => {
  //       console.log(error);
  //     });
  // }
  
}
