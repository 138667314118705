import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DataService } from 'src/app/service/data.service';

@Component({
  selector: 'app-order-summary-tmp',
  templateUrl: './order-summary-tmp.component.html',
  styleUrls: ['./order-summary-tmp.component.scss']
})

export class OrderSummaryTmpComponent implements OnInit {
  actCustomer = JSON.parse(localStorage.getItem('orderCustomer'));
  orderItems = JSON.parse(localStorage.getItem('orderItems')); // JSON.parse(localStorage.getItem('user'));
  orderItemPackage = JSON.parse(localStorage.getItem('orderItemPackage')); // JSON.parse(localStorage.getItem('user'));
  orderItemsAll;
  orderPriceAllItems = JSON.parse(localStorage.getItem('orderPriceAllItems')) // 
  orderPriceSpecialAllItems  = JSON.parse(localStorage.getItem('orderPriceSpecialAllItems')) // 
  orderCustomerDrive  = JSON.parse(localStorage.getItem('orderCustomerDrive')) // 
  description = localStorage.getItem('description')

  errorSend = false;
  successSend = false;

  Object = Object

  constructor( private router: Router ) { }
  ngOnInit( ){ this.orderItemsAll = [...this.orderItems, ...this.orderItemPackage]  }

  addPostOrderDetail() {
    const myHeaders = new Headers();
      myHeaders.append("LOGIN", "api@jirka.cz");
      myHeaders.append("PASSWORD", "Smazakpodebradsky");

      let formdata = new FormData();
      
      let orderItems = [];
      
      this.orderItems.map(item => orderItems.push( `
        {"ITEM_ID": "${String(item.ITEM_ID)}", 
        "QUANTITY": "${String(item.QUANTITY)}", 
        "ITEMS_PRICE_SPECIAL": "${String(item.ITEMS_PRICE_SPECIAL)}"
        }
        `))      
        
        this.orderItemPackage.map(item => orderItems.push( `
        {"ITEM_ID": "${String(item.ITEM_ID)}", 
        "QUANTITY": "${String(item.QUANTITY)}", 
        "ITEMS_PRICE_SPECIAL": "${String(item.ITEMS_PRICE_SPECIAL)}"
        }
        `))

      formdata.append('ORDER_DETAIL', `{ 
        "ORDER_INFO": {
        "CUSTOMER_ID": "${String(this.actCustomer.customerId)}",
        "DRIVER_ID": "${String(this.orderCustomerDrive.DRIVER_ID)}",
        "CREATED_BY": "${String(localStorage.getItem("USERID"))}",
        "DESCRIPTION": "${this.description}",
        "PRICE":${this.orderPriceAllItems},
        "PRICE_SPECIAL":${this.orderPriceSpecialAllItems},
        "ORDER_ITEMS": [ ${String(orderItems)} ] 
      }
    }`)
      
    let requestOptions: RequestInit = {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    };

    console.log("requestOptions")
    console.log(requestOptions)

    fetch("https://vojtas.plasanci.cz/API/ORDER_DETAIL.php", requestOptions)
      .then(response => response.text())
      .then(result => {console.log(result); this.urlSummaryToRozcestnik(); this.successSend = true})
      .catch(error => {console.log('error', error); this.errorSend = true });
  }

  urlSummaryToEdit() {
    this.router.navigateByUrl('/order-new')
  }  
  
  urlSummaryToRozcestnik() {
    this.router.navigateByUrl('/guide')
  }
  
}
