<app-title-page textTitle="Nová objednávka"></app-title-page>   

<app-order-customer></app-order-customer>
<hr>
<app-order-items></app-order-items>
<hr>
<app-order-items-packages></app-order-items-packages>
<hr>
<app-order-price></app-order-price>
<hr>
<app-order-button></app-order-button>